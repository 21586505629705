import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import TableGraphic from '../../graphic/table/TableGraphic';
import TableCell from '../../graphic/table/cells/TableCell';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TableGridAreas from '../../graphic/table/TableGridAreas';
import IComponentContainingText from '../IComponentContainingText';
import Editor from '../../mechanics/mext/editor';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import { AnySpatialArea } from '../../Types';
import ITableComponentTexture from './ITableComponentTexture';
import Utils from '../../utils/impl/Utils';
import ITableRowProperty from './ITableRowProperty';
import TableCellContext from '../../graphic/table/cells/context/TableCellContext';
import { TableGridMap } from '../../graphic/table/TableGridMap';
import IMutableTableComponent from './IMutableTableComponent';

/**
 * Компонент для отображения таблиц.
 */
class TableComponent
	extends GraphicComponent<ITableComponentTexture, TableGraphic>
	implements IComponentContainingText, IMutableTableComponent {
	public readonly type: SketchComponentType = SketchComponentType.TABLE;
	// Слушатели события автоматического изменения высоты ячеек.
	private readonly postCellInputExternalListeners: VoidFunction[];

	private readonly DEFAULT_ROW_HEIGHT = 32;
	private readonly DEFAULT_ROW_MARGIN = 20;

	private gridAreas: TableGridAreas;
	private cellContexts: TableCellContext[] | null;

	// Коллекция номеров абсолютных строк, с которых начинает отображать строки каждая графика.
	private startRows: number[];
	private borderColor: string;
	private columnMultipliers: number[];
	private rowsMultipliers: number[] | null;

	constructor() {
		super();
		this.startRows = [0];
		this.borderColor = '';
		this.cellContexts = null;
		this.rowsMultipliers = [];
		this.columnMultipliers = [];
		this.gridAreas = new TableGridAreas();
		this.postCellInputExternalListeners = [];
	}

	public mutateCellTextures = (cellTextures: ITableCellTexture[]) => {
		const cellContexts: TableCellContext[] = cellTextures.map(texture => {
			const context = new TableCellContext(texture);
			context.addPostChangeModelListener(this.postCellInputListener);
			return context;
		});

		this.cellContexts = [...cellContexts];

		const columnCount = this.getColumnCount();
		this.gridAreas.loadContexts(cellContexts, columnCount);
	};

	public mutateColumnMultipliers = (multipliers: number[]) => {
		this.columnMultipliers = multipliers;
	};

	public mutateRowMultipliers = (multipliers: number[]) => {
		this.rowsMultipliers = multipliers;
	};

	public mutateBorderColor = (color: string) => {
		this.borderColor = color;
	};

	public mutateStartRows = (startRows: number[]) => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('cell context not found');
		}

		this.startRows = startRows;
	};

	/**
	 * Инициализирует множители высоты строк, если изначально таблица была сохранена без них,
	 * например в старых версиях шаблонов, в которых ещё не был предусмотрен функционал высоты строк.
	 */
	public mutateInitRowMultipliers = () => {
		const defaultHeight = this.getDefaultRowHeight();
		const rowsProperties = this.getRowProperties();
		const multipliers: number[] = [];

		rowsProperties.forEach(property => multipliers.push(property.height / defaultHeight));

		this.rowsMultipliers = multipliers;
	};

	/**
	 * Синхронизирует реальную высоту строки с высотой в памяти по минимальной высоте контента внутри строки.
	 */
	public mutateSyncRealRowHeight = () => {
		if (this.rowsMultipliers === null) {
			throw new ManipulatorError('rows multipliers not initialized');
		}

		const rowCount = this.getRowCount();

		if (this.rowsMultipliers.length !== rowCount) {
			throw new ManipulatorError('invalid row heights count');
		}

		const defaultHeight = this.getDefaultRowHeight();
		const rowsMultipliers = [...this.rowsMultipliers];
		const cellsFromRow: TableCell[][] = this.getCellsFromRow();

		for (let i = 0; i < rowCount; i++) {
			if (cellsFromRow[i] !== undefined) {
				const realRowHeight = this.calculateRealRowHeight(cellsFromRow, i);
				if (rowsMultipliers[i] * defaultHeight !== realRowHeight) {
					rowsMultipliers[i] = realRowHeight / defaultHeight;
				}
			}
		}

		this.rowsMultipliers = rowsMultipliers;
	};

	public applyMutations = () => {
		const graphics = this.getGraphics();
		if (graphics.length === 0 || this.cellContexts === null) {
			return;
		}

		const columnCount = this.getColumnCount();
		const rowCountAll = this.getRowCount();

		this.cellContexts.forEach(context => context.setTableStartRows(this.startRows));
		this.gridAreas.update(columnCount);

		graphics.forEach((graphic, graphicIndex) => {
			const rowMultipliers = this.calculateRowMultipliersForGraphic(graphic);
			const startRow = this.startRows[graphicIndex];
			const nextStartRow = this.startRows[graphicIndex + 1];
			const rowCount = nextStartRow === undefined ? rowCountAll - startRow : nextStartRow - startRow;

			graphic.setStartRow(startRow);
			graphic.setRowCount(rowCount);
			graphic.setBorderColor(this.borderColor);
			graphic.setColumnMultipliers(this.columnMultipliers);
			graphic.setRowsMultipliers(rowMultipliers);
			graphic.renderCellLayer();
		});
	};

	public getEditors = (): Editor[] => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('the table cells not initialized');
		}

		return this.cellContexts.map(context => context.getEditor());
	};

	/**
	 * Возвращает все пространственные области таблицы.
	 * Перегрузка вызвана добавлением областей ячеек.
	 */
	public override getSpatialAreas = (): AnySpatialArea[] => {
		const areas: AnySpatialArea[] = [];
		const graphics = this.getGraphics();
		const cellAreas = this.getCellAreas();

		areas.push(
			...graphics.map(graphic => graphic.getSpatialAreas()).flat(),
			...cellAreas,
		);

		return areas;
	};

	public getColumnMultipliers = (): number[] => {
		if (this.columnMultipliers === null) {
			throw new ManipulatorError('the column multipliers not initialized');
		}
		return [...this.columnMultipliers];
	};

	public getRowsMultipliers = (): number[] => {
		if (this.rowsMultipliers === null) {
			throw new ManipulatorError('the rows multipliers not initialized');
		}
		return [...this.rowsMultipliers];
	};

	/**
	 * Возвращает базовую ширину колонки таблицы.
	 * Используется для вычисления фактической ширины колонки по множителю.
	 */
	public getDefaultColumnWidth = (): number => {
		const columnCount = this.getColumnCount();
		const { graphics } = this.getStructure();
		if (graphics === null) {
			throw new ManipulatorError('graphics can not be null');
		}

		const firstGraphic = graphics[0];
		if (firstGraphic === undefined) {
			throw new ManipulatorError('first graphic not found');
		}

		const instanceGraphics = this.getGraphics();
		const firstInstanceGraphic = instanceGraphics[0];
		if (firstInstanceGraphic === undefined) {
			throw new ManipulatorError('first instance graphic not found');
		}

		const frameConfiguration = firstInstanceGraphic.getFrameConfiguration();

		return frameConfiguration.width / columnCount;
	};

	/**
	 * Возвращает базовую высоту строки таблицы.
	 * Используется для вычисления фактической высоты строки по множителю.
	 */
	public getDefaultRowHeight = (): number => this.DEFAULT_ROW_HEIGHT;
	public getStartRows = () => this.startRows;

	public getColumnCount = (): number => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('contexts not found');
		}

		return this.calculateColumnCount(this.cellContexts);
	};

	public getRowCount = (): number => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('contexts not found');
		}

		return this.calculateRowCount(this.cellContexts);
	};

	public getBorderColor = () => this.borderColor;

	/**
	 * Возвращает словарь, в котором key - идентификатор ячейки, а value - сам объект ячейки.
	 * @param cells - структуры ячеек, по которым будет генерироваться словарь.
	 */
	public getIDCellToTableCell = (cells: TableCell[]): Map<string, TableCell> => {
		const map = new Map<string, TableCell>();
		cells.forEach(cell => map.set(cell.getID(), cell));
		return map;
	};

	public getFocusCellContexts = (): TableCellContext[] | null => {
		if (this.cellContexts === null) {
			return null;
		}

		const contexts = this.cellContexts.filter(context => context.hasFocus());
		return contexts.length === 0 ? null : contexts;
	};

	/**
	 * Находит наибольший индекс колонки (индекс самой правой колонки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getRightmostColumnIndex = (): number | null => {
		const focusContexts = this.getFocusCellContexts();
		if (focusContexts === null) {
			return null;
		}

		let rightmostIndex = 0;
		focusContexts.forEach(context => {
			const { column, columnSpan } = context.getTexture();
			const index = column + columnSpan - 1;
			rightmostIndex = index > rightmostIndex ? index : rightmostIndex;
		});

		return rightmostIndex;
	};

	/**
	 * Находит наименьший индекс колонки (индекс самой левой колонки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getLeftmostColumnIndex = (): number | null => {
		const focusContexts = this.getFocusCellContexts();
		if (focusContexts === null) {
			return null;
		}

		let leftmostIndex = Number.MAX_SAFE_INTEGER;
		focusContexts.forEach(context => {
			const { column } = context.getTexture();
			leftmostIndex = column < leftmostIndex ? column : leftmostIndex;
		});

		return leftmostIndex;
	};

	/**
	 * Находит наибольший индекс строки (индекс самой нижней строки) и возвращает все ячейки с этим индексом либо
	 * null если ячеек в фокусе нет.
	 */
	public getLowestIndex = (): number | null => {
		let lowestIndex = 0;
		const focusCells = this.getFocusCellContexts();
		if (focusCells === null) return null;
		focusCells.forEach(cell => {
			const cellRowSpan = cell.getTexture().rowSpan;
			const cellRow = cell.getTexture().row + cellRowSpan - 1;
			lowestIndex = cellRow > lowestIndex ? cellRow : lowestIndex;
		});

		return lowestIndex;
	};

	public getHighestIndex = (): number | null => {
		let highetsIndex = Number.MAX_SAFE_INTEGER;
		const focusCells = this.getFocusCellContexts();
		if (focusCells === null) return null;
		focusCells.forEach(cell => {
			const cellRow = cell.getTexture().row;
			highetsIndex = cellRow < highetsIndex ? cellRow : highetsIndex;
		});

		return highetsIndex;
	};

	/**
	 * Возвращает коллекцию высот каждой абсолютной строки таблицы в пикселях.
	 */
	public getRowProperties = (): ITableRowProperty[] => {
		const rowMinCells = this.getRowMinCells();
		return rowMinCells.map(cell => ({
			height: cell.getHeight(),
			rowSpan: cell.getRowSpan(),
		}));
	};

	public getCells = (): TableCell[] => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('the table cells not initialized');
		}
		return this.cellContexts.map(context => context.getTableCells()).flat();
	};

	public getCellContexts = (): TableCellContext[] => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('the table cell contexts not initialized');
		}
		return [...this.cellContexts];
	};

	public getGridAreas = (): TableGridMap => this.gridAreas.getMap();

	public getTexture = (): ITableComponentTexture => ({
		startRows: this.startRows,
		borderColor: this.borderColor,
		columnMultipliers: this.columnMultipliers,
		rowMultipliers: this.rowsMultipliers,
		cells: this.cellContexts === null
			? []
			: this.cellContexts.map(cell => cell.getTexture()),
	});

	public getUniqueTexture = (): ITableComponentTexture => {
		if (
			this.cellContexts === null
			|| this.columnMultipliers === null
			|| this.borderColor === null
		) {
			throw new ManipulatorError('table not initialized');
		}

		return {
			startRows: this.startRows,
			borderColor: this.borderColor,
			columnMultipliers: this.columnMultipliers,
			rowMultipliers: this.rowsMultipliers,
			cells: this.cellContexts.map(cell => cell.getTexture()).map(texture => ({
				...texture,
				id: Utils.Generate.UUID4(),
				content: {
					...texture.content,
					id: Utils.Generate.UUID4(),
				},
			})),
		};
	};

	public setTexture = (fn: (prev: ITableComponentTexture) => ITableComponentTexture) => {
		const current = this.getTexture();
		const {
			cells, columnMultipliers, borderColor, rowMultipliers, startRows,
		} = fn(current);

		this.startRows = startRows;
		this.borderColor = borderColor;
		this.rowsMultipliers = rowMultipliers;
		this.columnMultipliers = columnMultipliers;

		this.mutateCellTextures(cells);
		this.applyMutations();

		if (rowMultipliers === null) {
			setTimeout(this.mutateInitRowMultipliers, 0);
			setTimeout(this.applyMutations, 0);
		}
	};

	/**
	 * Включает фокус у ячейки по её идентификатору и снимает его со всех остальных.
	 * В случае отсутствия ячейки метод возвращает `false`.
	 * @param cellID Идентификатор ячейки.
	 */
	public focusOnlyCellContextByID = (cellID: string): boolean => {
		if (this.cellContexts === null) {
			return false;
		}

		const targetCell = this.cellContexts.find(cell => cell.getID() === cellID);
		if (targetCell === undefined) {
			return false;
		}

		this.cellContexts.forEach(cell => {
			if (cell === targetCell) {
				cell.enableFocus();
				cell.enableMutationMode();
				cell.enableTextFocus();
				return;
			}
			cell.disableFocus();
		});
		return true;
	};

	public addPostCellInputListener = (listener: VoidFunction) => {
		this.postCellInputExternalListeners.push(listener);
	};

	/**
	 * Вычисляет и возвращает массив множителей строк для переданной графики таблицы.
	 * @param graphic графика таблицы, для которой необходимо вычислить множитель строк
	 * @return number[] - возвращает массив множителей строк для графики таблицы
	 */
	public calculateRowMultipliersForGraphic = (graphic: TableGraphic) : number[] | null => {
		if (this.rowsMultipliers === null) {
			return null;
		}

		const { rowCount } = graphic.getTexture();
		const graphics = this.getGraphics();
		const targetGraphic = graphics.indexOf(graphic);
		if (targetGraphic === -1) {
			throw new ManipulatorError('graphic not found');
		}
		const startRow = this.startRows[targetGraphic];
		if (startRow === undefined) {
			throw new ManipulatorError('start row not found');
		}

		return this.rowsMultipliers.slice(startRow, startRow + rowCount);
	};

	private getCellAreas = (): SpatialTableCellArea[] => {
		if (this.cellContexts === null) {
			throw new ManipulatorError('table cell not initialized');
		}

		const areas: SpatialTableCellArea[] = [];
		const cellContextToGraphic = this.getCellContextToGraphic();

		cellContextToGraphic.forEach((graphic, cellContext) => {
			const cells = cellContext.getTableCells();
			for (let i = 0; i < cells.length; i++) {
				areas.push(new SpatialTableCellArea(this, graphic, cellContext, cells[i]));
			}
		});

		return areas;
	};

	/**
	 * Возвращает карту графики с ячейки, где ключ - ячейка, а значение - графика, где она визуализируется.
	 */
	private getCellContextToGraphic = (): Map<TableCellContext, TableGraphic> => {
		const map: Map<TableCellContext, TableGraphic> = new Map();
		const graphics = this.getGraphics();

		graphics.forEach(graphic => {
			const cells = graphic.getCells();
			// TODO контекст может быть представлен в нескольких графиках, могут быть побочные эффекты
			const contexts = new Set(cells.map(cell => cell.getContext()));
			contexts.forEach(context => {
				map.set(context, graphic);
			});
		});

		return map;
	};

	/**
	 * Снимает фокус со всех ячеек.
	 */
	private disableCellFocus = () => {
		const focusCells = this.getFocusCellContexts();
		if (focusCells === null) {
			return;
		}

		focusCells.forEach(cell => cell.disableFocus());
	};

	private postCellInputListener = () => {
		setTimeout(this.syncGraphicsHeight.bind(this), 0);
		setTimeout(this.mutateSyncRealRowHeight.bind(this), 0);
		setTimeout(this.applyMutations.bind(this), 0);
		setTimeout(this.callPostCellInputExternalListeners.bind(this), 0);
	};

	private callPostCellInputExternalListeners = () => {
		this.postCellInputExternalListeners.forEach(listener => listener());
	};

	/**
	 * Вычисляет и возвращает высоту строки на основе контента внутри, включая отступ.
	 * @param cellsFromRow Коллекция ячеек в каждой строке таблицы.
	 * @param rowNumber Номер строки, для которой необходимо вычислить высоту.
	 */
	private calculateRealRowHeight = (cellsFromRow: TableCell[][], rowNumber: number) : number => Math
		.max(...cellsFromRow[rowNumber].map(cell => {
			const element = cell.getElement();
			const children = element.children[element.children.length - 1];
			const height = children === undefined ? element.scrollHeight : children.scrollHeight;
			return height + this.DEFAULT_ROW_MARGIN;
		}));

	/**
	 * Вычисляет и возвращает массив ячеек по строкам.
	 * @return TableCell[][] Возвращает массив ячеек для каждой строки
	 */
	private getCellsFromRow = () : TableCell[][] => {
		const cells = this.getCells();

		const cellsFromRow: TableCell[][] = [];
		cells.forEach((cell => {
			if (cell.isContextExtend) {
				return;
			}

			const rowSpan = cell.getRowSpan();
			if (rowSpan !== 1) {
				return;
			}
			const row = cell.getRow();

			if (cellsFromRow[row] === undefined) {
				cellsFromRow[row] = [];
			}

			cellsFromRow[row].push(cell);
		}));
		return cellsFromRow;
	};

	private calculateColumnCount = (contexts: TableCellContext[]): number => Math.max(...contexts.map(context => {
		const { column, columnSpan } = context.getTexture();
		return column + columnSpan;
	}));

	private calculateRowCount = (contexts: TableCellContext[]): number => Math.max(...contexts.map(context => {
		const { row, rowSpan } = context.getTexture();
		return row + rowSpan;
	}));

	/**
	 * Возвращает минимальную по объединению строк ячейку в каждой логической строке.
	 */
	private getRowMinCells = (): TableCell[] => {
		const rowMinCells = [];
		const cells = this.getCells();
		const gridMap = this.gridAreas.getMap();
		const cellIdentityToCell = this.getIDCellToTableCell(cells);

		for (let i = 0; i < gridMap.length; i++) {
			let minSpan = Number.MAX_SAFE_INTEGER;
			let minCell: TableCell | null = null;
			for (let j = 0; j < gridMap[i].length; j++) {
				const cell = cellIdentityToCell.get(gridMap[i][j]);
				if (cell === undefined) {
					throw new ManipulatorError('cell not found');
				}
				const rowSpan = cell.getRowSpan();
				if (minCell === null || rowSpan < minSpan) {
					minSpan = rowSpan;
					minCell = cell;
				}
			}

			if (minCell === null) {
				throw new ManipulatorError('min cell not found');
			}
			rowMinCells.push(minCell);
			i += minSpan - 1;
		}

		return rowMinCells;
	};
}

export default TableComponent;
