import ComponentCloner from './component-cloner/ComponentCloner';
import IDescartesPosition from '../utils/IDescartesPosition';
import IComponent from '../components/IComponent';
import SketchComponentType from '../components/SketchComponentType';
import TextComponent from '../components/text/TextComponent';
import { notificationError } from '../../Notifications/callNotifcation';
import TableComponent from '../components/table/TableComponent';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import IClonerClipboard from './component-cloner/IClonerClipboard';
import ComponentBuilder from '../factories/ComponentBuilder';
import IGraphicFactory from '../factories/graphic/IGraphicFactory';
import IComponentFactory from '../factories/component/IComponentFactory';
import IGraphic from '../graphic/IGraphic';
import ComponentInjector from '../component-injector/ComponentInjector';
import { Token, TokenFormat, TokenType } from './mext/parser';
import { FontFamily, FontSize, TextAlign } from './mext/editor/types';
import MousePositionObserver from '../utils/observers/MousePositionObserver';
import Utils from '../utils/impl/Utils';
import Dependent from '../utils/dependent/Dependent';

interface IPasteCoordinatorDependencies {
    componentCloner: ComponentCloner,
	graphicFactory: IGraphicFactory,
	componentFactory: IComponentFactory,
	componentInjector: ComponentInjector,
	mouseObserver: MousePositionObserver,
}

class PasteCoordinator extends Dependent<IPasteCoordinatorDependencies> {
	/**
	 * Читает данные из буфера обмена.
	 */
	private readInBuffer = async (): Promise<string> => {
		let blobText = '';
		try {
			const bufferData = await navigator.clipboard.read();
			for (const item of bufferData) {
				if (item.types.includes('web wakadoo/fragment')) {
					// eslint-disable-next-line no-await-in-loop
					const blob = await item.getType('web wakadoo/fragment');
					// eslint-disable-next-line no-await-in-loop
					blobText = await blob.text();
					blobText = Utils.Text.sanitize(blobText);
				} else {
					// eslint-disable-next-line no-await-in-loop
					const blob = await item.getType('text/plain');
					// eslint-disable-next-line no-await-in-loop
					blobText = await blob.text();
					blobText = Utils.Text.sanitize(blobText).trim();
				}
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log(error.message);
		}
		return blobText;
	};

	/**
	 * Координирует данные для последующей вставки.
	 * @param focusComponents - компоненты, в которые будет производиться вставка текста.
	 * @param position - позиция для вставки компонента.
	 */
	private onPaste = async (focusComponents?: IComponent[] | null, position?: IDescartesPosition) => {
		const blobText = await this.readInBuffer();
		if (blobText === '') {
			return;
		}
		// Если bufferData начинается с префикса "JSON:", выполняем вставку компонента
		if (blobText.startsWith('JSON:')) {
			const clonerClipboard = this.pullClipBoard(blobText);
			const firstGraphic = this.getBufferFirstGraphic(blobText);
			// Если указана позиция, вставляем компонент на эту позицию
			if (position) {
				await this.dependencies.componentCloner.pasteAtPosition(position, firstGraphic, clonerClipboard);
			} else {
				const mousePosition = this.dependencies.mouseObserver.getCurrentPosition();
				await this.dependencies.componentCloner.pasteAtPosition(mousePosition, firstGraphic, clonerClipboard);
			}
			// Если есть компоненты в фокусе и они находятся в режиме редактирования, выполняем вставку текста в них
		} else if (focusComponents !== null
			&& focusComponents !== undefined
			&& focusComponents[0].isEnableEditMode()) {
			const tokens = this.convertBlobTextToTokens(blobText);
			this.pasteTokensToComponent(focusComponents[0], tokens);
		} else {
			// Иначе создаем компонент текста и вставляем скопированные данные в него
			const tokens = this.convertBlobTextToTokens(blobText);
			this.dependencies.componentInjector.runInjectTextWithContent(tokens);
		}
	};

	public pasteAtPosition = (focusComponents: IComponent[] | null, position: IDescartesPosition) => {
		this.onPaste(focusComponents, position);
	};

	public pasteInComponents = (focusComponents: IComponent[] | null) => {
		this.onPaste(focusComponents);
	};

	public pasteFromBuffer = () => {
		this.onPaste();
	};

	/**
	 * Достает из буфера обмена скопированные структуры компонентов и указатель на ID скетча,
	 * где последний раз происходила вставка.
	 * @param bufferData - данные из буфера обмена
	 */
	private pullClipBoard = (bufferData: string): IClonerClipboard => {
		const plainText = bufferData.substring(5);
		let clonerClipboard: IClonerClipboard;

		try {
			clonerClipboard = JSON.parse(plainText) as IClonerClipboard;
		} catch (error) {
			throw new ManipulatorError(error as string);
		}

		return clonerClipboard;
	};

	/**
	 * Извлекает из буфера обмена скопированный токен текста
	 * @return возвращает токен текста в виде строки или массива токенов.
	 */
	private convertBlobTextToTokens = (blobText: string): Token[] => {
		let tokens: Token[] = [];
		try {
			// если текст был скопирован из редактора wakadoo то выполниться это условие
			if (blobText.startsWith('TEXT:')) {
				const blobTextSubstring = blobText.substring(5);
				tokens = JSON.parse(blobTextSubstring) as Token[];
			} else {
				const newToken: Token[] = [];
				newToken.push({
					fontFamily: FontFamily.Default,
					fontSize: FontSize.Pt14,
					color: '#000000',
					textAlign: TextAlign.LEFT,
					type: TokenType.Text,
					value: blobText,
					format: TokenFormat.None,
					lineHeight: 1,
					sticky: false,
				});
				const newTokenJson = JSON.stringify(newToken);
				tokens = JSON.parse(newTokenJson) as Token[];
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log(error.message);
		}
		return tokens;
	};

	/**
	 * Достает из буфера обмена первую графику первого скопированного компонента для расчета позиции при вставке.
	 * @param bufferData - данные из буфера обмена
	 */
	private getBufferFirstGraphic = (bufferData: string): IGraphic => {
		const plainText = bufferData.substring(5);
		let clonerClipboard: IClonerClipboard;

		try {
			clonerClipboard = JSON.parse(plainText) as IClonerClipboard;
		} catch (error) {
			throw new ManipulatorError(error as string);
		}

		if (clonerClipboard === null) {
			throw new ManipulatorError('clonerClipboard is null');
		}
		if (clonerClipboard.structures.length === 0) {
			throw new ManipulatorError('buffer is empty');
		}

		const builder = new ComponentBuilder();
		builder.connectDependencies({
			graphicFactory: this.dependencies.graphicFactory,
			componentFactory: this.dependencies.componentFactory,
		});
		builder.injectDependencies();
		builder.scanStructure(clonerClipboard.structures[0]);

		const component = builder.getComponent();

		return component.getGraphics()[0];
	};

	/**
	 * Запускает событие вставки текста в компонент.
	 * @param component Компонент, в который вставляется текст.
	 */
	private pasteTokensToComponent = (component: IComponent, tokens: Token[]) => {
		if (component.type === SketchComponentType.TEXT) {
			const editors = (component as TextComponent).getEditors();
			if (!editors[0].isActiveEditable()) {
				notificationError('Копирование текста', 'Текст не в режиме редактирования');
			}
			editors[0].onPaste(tokens);
		}
		if (component.type === SketchComponentType.TABLE
			&& (component as TableComponent).getFocusCellContexts()) {
			const focusCells = (component as TableComponent).getFocusCellContexts();
			if (focusCells === null) return;
			const editors = focusCells.map((cell) => cell.getEditor());
			const editableEditor = editors.find((editor) => editor.isActiveEditable());
			if (editableEditor === undefined) {
				notificationError('Копирование текста', 'Нет ячейки в режиме редактирования');
				throw new ManipulatorError('not have editable cell');
			}
			editableEditor.onPaste(tokens);
		}
	};
}

export default PasteCoordinator;
