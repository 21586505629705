import IComponent from '../../components/IComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ComponentLayers from '../../mechanics/layers/ComponentLayers';
import IGraphic from '../../graphic/IGraphic';
import IGraphicFactory from '../../factories/graphic/IGraphicFactory';
import ComponentTree from './ComponentTree';
import IComponentTreeMutator from '../IComponentTreeMutator';
import IComponentFactory from '../../factories/component/IComponentFactory';
import SketchComponentType from '../../components/SketchComponentType';
import PagesContainer from '../../components/pages-container/PagesContainer';
import { AnyComponentStructure, LayerSequences } from '../../Types';
import TableComponent from '../../components/table/TableComponent';
import IMutationTools from '../IMutationTools';
import ComponentBuilder from '../../factories/ComponentBuilder';
import GraphicType from '../../graphic/GraphicType';
import Stack from '../../structures/Stack';
import ILayeredComponentTree from '../ILayeredComponentTree';

export interface IMutableComponentTreeDependencies {
	graphicFactory: IGraphicFactory,
	componentFactory: IComponentFactory,
}

/**
 * Изменяемое дерево компонентов. Предоставляет методы для изменения структуры дерева.
 * Берет на себя задачу управления побочными эффектами при изменении структуры.
 * @inheritDoc
 */
abstract class MutableComponentTree<Dependencies extends IMutableComponentTreeDependencies>
	extends ComponentTree<Dependencies>
	implements IComponentTreeMutator, ILayeredComponentTree {
	private readonly componentBuilder: ComponentBuilder;

	protected rootComponent: IComponent;

	protected componentLayers: ComponentLayers;

	protected constructor(manipulatorElement: HTMLElement) {
		super(manipulatorElement);
		this.componentBuilder = new ComponentBuilder();
		this.componentLayers = new ComponentLayers(this);

		this.addPostInjectDependenciesListener(dependencies => {
			this.componentBuilder.connectDependencies({
				graphicFactory: dependencies.graphicFactory,
				componentFactory: dependencies.componentFactory,
			});
		});
	}

	/**
	 * Загружает структуру корневого компонента и визуализирует её.
	 * @param structure - структура корневого компонента.
	 */
	public load = (structure: AnyComponentStructure) => {
		this.rootComponent = this.dependencies.componentFactory.createComponent(structure);

		structure.graphics?.forEach(graphicStructure => {
			const graphic = this.dependencies.graphicFactory.createGraphic(graphicStructure.type, this.rootComponent);
			graphic.setStructure(() => graphicStructure);
			this.rootComponent.appendGraphic(graphic);
		});

		if (structure.components !== null) {
			this.recursiveInitializeAppendComponent(this.rootComponent, structure.components);
		}

		this.componentLayers.load(structure);
	};

	public reset = () => {
		const graphics = this.rootComponent.getGraphics();
		graphics.forEach(graphic => {
			graphic.removeFrame();
		});

		this.componentLayers.reset();
	};

	/**
	 * Выполняет набор мутаций и запускает побочные эффекты.
	 *
	 * Example:
	 * mutations() {
	 *     .mutateByAppendComponent(a1, a2);
	 *     .mutateByAppendComponent(a2, a3);
	 *     // actions
	 *     .mutateByAppendComponent(a1, a4);
	 * }
	 * @param mutations - функция, содержащая последовательность мутаций.
	 */
	public executeMutations = (mutations: (tools: IMutationTools) => void) => {
		mutations({
			mutator: this,
			componentFactory: this.dependencies.componentFactory,
			graphicFactory: this.dependencies.graphicFactory,
			componentLayers: this.componentLayers,
			componentBuilder: this.componentBuilder,
		});
	};

	/**
	 * Добавляет компонент в другой компонент.
	 * @param parentComponent - родительский компонент.
	 * @param component - вкладываемый компонент.
	 */
	public mutateByAppendComponent = (parentComponent: IComponent, component: IComponent) => {
		const componentGraphics = component.getGraphics();
		const componentStructure = component.getStructure();
		const parentGraphics = parentComponent.getGraphics();

		componentGraphics.forEach(graphic => {
			if (componentStructure.offset === null) {
				throw new ManipulatorError('component offset is null');
			}

			const offset = graphic.getOffset();
			const parentGraphic = parentGraphics[componentStructure.offset + offset];
			if (parentGraphic === undefined) {
				throw new ManipulatorError('parent graphic not found');
			}

			const graphicElement = graphic.getFrameElement();
			const parentElement = parentGraphic.getGraphicElement();

			parentElement.append(graphicElement);
			this.componentLayers.appendLayerAfter(graphic, parentGraphic);
		});

		if (component.type === SketchComponentType.TABLE) {
			setTimeout((component as unknown as TableComponent).applyMutations.bind(this), 0);
		}

		parentComponent.appendComponent(component);
	};

	/**
	 * Добавляет графику в компонент. Используется только при изменении уже внедренного компонента
	 * в общее дерево.
	 * @param component - компонент, с графикой который мы работаем.
	 * @param graphic - добавляемая графика.
	 */
	public mutateByAppendGraphic = (component: IComponent, graphic: IGraphic): IGraphic => {
		// Запишем на какой странице находится компонент
		const componentOffset = component.getOffset();
		if (componentOffset === null) {
			throw new ManipulatorError('the component is not connected to component tree');
		}

		const graphicElement = graphic.getFrameElement();
		const parentComponent = component.getParentComponent();
		if (parentComponent === null) {
			if (component.type === SketchComponentType.PAGES_CONTAINER) {
				const containerElement = (component as unknown as PagesContainer).getPagesContainerElement();
				containerElement.append(graphicElement);
			} else {
				throw new ManipulatorError('parent component not found');
			}
		} else {
			const componentGraphics = component.getGraphics();
			const parentGraphics = parentComponent.getGraphics();
			const graphicOffset = componentGraphics.length;
			const parentGraphic = parentGraphics[componentOffset + graphicOffset];
			const parentElement = parentGraphic.getGraphicElement();

			parentElement.append(graphicElement);
		}

		component.appendGraphic(graphic);

		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			const offset = graphic.getOffset();
			this.componentLayers.appendLayer(graphic, offset);
			return graphic;
		}

		this.componentLayers.appendLayerAfter(graphic, parentGraphic);
		return graphic;
	};

	/**
	 * Добавляет графику в компонент после определенной графики. Используется только при изменении
	 * уже внедренного компонента в общее дерево.
	 * @param component Компонент, с графикой который мы работаем.
	 * @param targetGraphic Графика, после которой будет вставлена передаваемая графика.
	 * @param graphic Добавляемая графика.
	 */
	public mutateByAppendGraphicAfter = (
		component: IComponent,
		targetGraphic: IGraphic,
		graphic: IGraphic,
	): IGraphic => {
		// Вычисление списка графики для последующего сдвига.
		let graphics = component.getGraphics();
		const targetGraphicIndex = graphics.indexOf(targetGraphic);
		if (targetGraphicIndex === -1) {
			throw new ManipulatorError('graphic not found');
		}

		// Регистрация графики в компоненте.
		component.appendGraphicAfter(targetGraphic, graphic);

		if (component.type === SketchComponentType.PAGES_CONTAINER) {
			this.componentLayers.appendPage(graphic, targetGraphicIndex + 1);
		} else {
			const parentGraphic = this.syncGraphicOffsetToDOM(graphic);
			this.componentLayers.appendLayerAfter(graphic, parentGraphic);
		}

		graphics = component.getGraphics();

		if (graphic.type === GraphicType.PAGE) {
			const prevElement = targetGraphic.getFrameElement();
			const graphicElement = graphic.getFrameElement();
			prevElement.after(graphicElement);
		} else {
			graphics.forEach(graphic => this.syncGraphicOffsetToDOM(graphic));
		}

		return graphic;
	};

	/**
	 * Добавляет графику в компонент перед определенной графикой.
	 * Используется только при изменении уже внедренного компонента в общее дерево.
	 * @param component Компонент, с графикой который мы работаем.
	 * @param targetGraphic Графика, перед которой будет вставлена передаваемая графика.
	 * @param graphic Добавляемая графика.
	 */
	public mutateByAppendGraphicBefore = (
		component: IComponent,
		targetGraphic: IGraphic,
		graphic: IGraphic,
	): IGraphic => {
		// Вычисление списка графики для последующего сдвига.
		let graphics = component.getGraphics();
		const targetGraphicIndex = graphics.indexOf(targetGraphic);

		// Уменьшение офсета на -1 для сохранения визуального позиционирования.
		const componentOffset = component.getOffset();
		if (componentOffset === null) {
			throw new ManipulatorError('component offset is null');
		}

		// Регистрация графики в компоненте.
		component.appendGraphicBefore(targetGraphic, graphic);

		if (component.type === SketchComponentType.PAGES_CONTAINER) {
			this.componentLayers.appendPage(graphic, targetGraphicIndex - 1);
		} else {
			const parentGraphic = this.syncGraphicOffsetToDOM(graphic);
			this.componentLayers.appendLayerAfter(graphic, parentGraphic);
		}

		this.mutateByChangeOffset(component, componentOffset - 1);

		// Физическая синхронизация офсета и положения графики в DOM.
		graphics = component.getGraphics();

		if (graphic.type === GraphicType.PAGE) {
			const prevElement = targetGraphic.getFrameElement();
			const graphicElement = graphic.getFrameElement();
			prevElement.before(graphicElement);
		} else {
			graphics.forEach(graphic => this.syncGraphicOffsetToDOM(graphic));
		}

		// Увеличение офсета на +1 для сохранения визуального позиционирования дочерних компонентов.
		const components = component.getComponents();
		if (components !== null) {
			components.forEach(component => {
				const currentOffset = component.getOffset();
				if (currentOffset === null) {
					throw new ManipulatorError('current offset is null');
				}

				this.mutateByChangeOffset(component, currentOffset + 1);
			});
		}

		return graphic;
	};

	/**
	 * Удаляет компонент.
	 * @param component - удаляемый компонент.
	 */
	public mutateByRemoveComponent = (component: IComponent) => {
		const components: IComponent[] = [component, ...component.getComponentAll()];

		components.forEach(component => {
			const graphics = component.getGraphics();
			graphics.forEach(graphic => {
				graphic.removeFrame();
				this.componentLayers.removeLayer(graphic);
			});

			const parentComponent = component.getParentComponent();
			if (parentComponent === null) {
				throw new ManipulatorError('parent component not found');
			}

			parentComponent.removeComponent(component);
		});
	};

	/**
	 * Обнаруживает графику компонентов-объединителей, которые не содержать дочернюю графику, и удаляет их.
	 */
	public mutateByRemoveEmptyUniterGraphics = () => {
		const uniterComponents = this.getUniterComponents();
		if (uniterComponents === null) {
			return;
		}

		uniterComponents.forEach(component => {
			const graphics = component.getGraphics();
			const toDeleteFromStartGraphic: IGraphic[] = [];
			const toDeleteFromEndGraphic: Stack<IGraphic> = new Stack<IGraphic>();

			let fromStart = true;
			graphics.forEach(graphic => {
				const containedGraphics = this.getInternalGraphics(graphic);
				if (containedGraphics !== null) {
					if (fromStart) {
						fromStart = false;
					}
					if (toDeleteFromEndGraphic.size() !== 0 && !fromStart) {
						throw new ManipulatorError('the group has an empty graphic in the middle of the component');
					}
					return;
				}

				if (fromStart) {
					toDeleteFromStartGraphic.push(graphic);
				} else {
					toDeleteFromEndGraphic.push(graphic);
				}
			});

			toDeleteFromStartGraphic.forEach(graphic => this.mutateByRemoveGraphicWithCorrectOffsets(graphic));

			let graphic = toDeleteFromEndGraphic.pop();
			while (graphic) {
				this.mutateByRemoveGraphicWithCorrectOffsets(graphic);
				graphic = toDeleteFromEndGraphic.pop();
			}
		});
	};

	/**
	 * Удаляет графику, учитывая изменение последовательности слоев и офсета затронутых компонентов.
	 * @param graphic Удаляемая графика.
	 */
	public mutateByRemoveGraphicWithCorrectOffsets = (graphic: IGraphic) => {
		const parentComponent = graphic.getParentComponent();
		if (parentComponent == null) {
			throw new ManipulatorError('parent component not found');
		}

		const graphics = parentComponent.getGraphics();
		const graphicIndex = graphics.indexOf(graphic);
		if (graphicIndex === undefined) {
			throw new ManipulatorError('graphic not found');
		}

		const forChangeGraphics = graphics.slice(graphicIndex + 1);
		const internalGraphics: IGraphic[] = [];

		forChangeGraphics.forEach(forChangeGraphic => {
			const graphics = this.getInternalGraphics(forChangeGraphic);
			if (graphics === null) {
				return;
			}

			internalGraphics.push(...graphics);
		});

		const forChangeComponents: Set<IComponent> = new Set();
		internalGraphics.forEach(graphic => {
			const component = graphic.getParentComponent();
			if (component === null) {
				throw new ManipulatorError('component is null');
			}
			forChangeComponents.add(component);
		});

		this.mutateByRemoveGraphic(graphic);

		if (graphicIndex === 0) {
			forChangeComponents.forEach(component => {
				const currentOffset = component.getOffset();
				if (currentOffset === null || currentOffset === 0) {
					throw new ManipulatorError('invalid current offset', {
						currentOffset,
						component: component.getStructure(),
					});
				}

				this.mutateByChangeOffset(component, currentOffset - 1);
			});

			// Изменить офсет компонента родителя, если удаляется первая графика.

			const parentComponentOffset = parentComponent.getOffset();
			if (parentComponentOffset === null) {
				throw new ManipulatorError('parent component offset not found');
			}
			this.mutateByChangeOffset(parentComponent, parentComponentOffset + 1);
		}
	};

	/**
	 * Удаляет графику компонента.
	 * @param graphic Удаляемая графика.
	 */
	public mutateByRemoveGraphic = (graphic: IGraphic) => {
		const parentComponent = graphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		parentComponent.removeGraphic(graphic);

		this.componentLayers.removeLayer(graphic);
	};

	/**
	 * Устанавливает новый сдвиг компонента относительно родительского и перерисовывает компонент.
	 * @param component обновляемый компонент.
	 * @param updatedOffset новый сдвиг.
	 */
	public mutateByChangeOffset = (component: IComponent, updatedOffset: number) => {
		if (updatedOffset < 0) {
			throw new ManipulatorError('invalid offset');
		}

		const currentStructure = component.getStructure();
		if (currentStructure.offset === updatedOffset) {
			return;
		}

		const parentComponent = component.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}

		const componentGraphics = component.getGraphics();
		const parentGraphics = parentComponent.getGraphics();

		if (parentGraphics.length < updatedOffset + componentGraphics.length) {
			throw new ManipulatorError('insufficient parent graphics');
		}

		let parentOffset = updatedOffset;
		let parentGraphic = parentGraphics[parentOffset];
		componentGraphics.forEach(graphic => {
			const graphicElement = graphic.getFrameElement();
			let parentElement: HTMLElement;
			if (parentGraphic.type === GraphicType.PAGE) {
				parentElement = parentGraphic.getGraphicElement();
			} else {
				parentElement = parentGraphic.getFrameElement();
			}

			parentElement.append(graphicElement);

			this.componentLayers.removeLayer(graphic);
			this.componentLayers.appendLayerAfter(graphic, parentGraphic);

			parentOffset++;
			parentGraphic = parentGraphics[parentOffset];
		});

		component.setStructure(prev => ({
			...prev,
			offset: updatedOffset,
		}));
	};

	/**
	 * Добавляет компонент с вложенными компонентами в структуру. Возвращает созданный компонент.
	 * @param parentComponent - родительский компонент.
	 * @param componentStructure - структура добавляемого компонента.
	 */
	public mutateByAppendMultiComponent = (
		parentComponent: IComponent,
		componentStructure: AnyComponentStructure,
	): IComponent => this.recursiveAppendComponent(parentComponent, componentStructure);

	public moveBackComponents = (...components: IComponent[]): void => {
		components.forEach(component => {
			component.getGraphics().forEach(graphic => {
				this.componentLayers.moveBackGraphic(graphic);
			});
		});
	};

	public moveForwardComponents = (...components: IComponent[]): void => {
		components.forEach(component => {
			component.getGraphics().forEach(graphic => {
				this.componentLayers.moveForwardGraphic(graphic);
			});
		});
	};

	public moveComponentToBackground = (...components: IComponent[]): void => {
		components.forEach(component => {
			component.getGraphics().forEach(graphic => {
				this.componentLayers.moveGraphicToBackground(graphic);
			});
		});
	};

	public moveComponentToForeground = (...components: IComponent[]): void => {
		components.forEach(component => {
			component.getGraphics().forEach(graphic => {
				this.componentLayers.moveGraphicToForeground(graphic);
			});
		});
	};

	public moveBackGraphics = (...graphics: IGraphic[]): void => {
		graphics.forEach(graphic => this.componentLayers.moveBackGraphic(graphic));
	};

	public moveForwardGraphics = (...graphics: IGraphic[]): void => {
		graphics.forEach(graphic => this.componentLayers.moveForwardGraphic(graphic));
	};

	public moveGraphicToBackground = (...graphics: IGraphic[]): void => {
		graphics.forEach(graphic => this.componentLayers.moveGraphicToBackground(graphic));
	};

	public moveGraphicToForeground = (...graphics: IGraphic[]): void => {
		graphics.forEach(graphic => this.componentLayers.moveGraphicToForeground(graphic));
	};

	public mutateByRemoveFocusComponents = (): void => {
		const focusComponents = this.getFocusComponents();
		if (focusComponents === null) return;

		const res = new Set<IComponent>();

		/* Для каждого компонента в фокусе поднимаемся по дереву на самый верх  */
		focusComponents.forEach((focusComponent) => {
			let parentComponent = focusComponent.getParentComponent();
			let resultComponent = focusComponent;

			if (parentComponent !== null) {
				while (parentComponent !== null) {
					const isIncluded = focusComponents.includes(parentComponent);
					if (isIncluded) resultComponent = parentComponent;
					parentComponent = parentComponent.getParentComponent();
				}
			}
			res.add(resultComponent);
		});

		res.forEach(component => {
			this.executeMutations(tools => tools.mutator.mutateByRemoveComponent(component));
		});
	};

	public setLayerSequences = (sequences: LayerSequences): void => {
		this.componentLayers.setSequences(sequences);
	};

	public getLayerSequences = (): LayerSequences => this.componentLayers.getSequences();

	public getLastLayerFromRootGraphicNumber = (pageNumber: number): IGraphic => this.componentLayers
		.getLastLayerGraphicFromPage(pageNumber);

	public getElementForEmbedding = (): HTMLElement => this.rootComponent.getGraphics()[0].getFrameElement();

	public mutateByChangeLayer = (prevGraphic: IGraphic, graphic: IGraphic): void => {
		this.componentLayers.moveGraphicAfter(prevGraphic, graphic);
	};

	public getWorkAreaElement = (): HTMLElement => this.manipulatorElement;

	public getPrevLayerGraphic = (graphic: IGraphic): IGraphic | null => this.componentLayers.getPrevGraphic(graphic);

	/**
	 * Метод рекурсивного добавления компонентов в структуру при инициализации конструктора.
	 * @param parentComponent - родительский компонент.
	 * @param componentStructures - структуры добавляемых компонентов.
	 */
	protected recursiveInitializeAppendComponent = (
		parentComponent: IComponent,
		componentStructures: AnyComponentStructure[],
	) => {
		const parentComponentGraphics = parentComponent.getGraphics();

		componentStructures.forEach(componentStructure => {
			const component = this.dependencies.componentFactory.createComponent(componentStructure);

			componentStructure.graphics?.forEach(graphicStructure => {
				const graphic = this.dependencies.graphicFactory.createGraphic(graphicStructure.type, component);

				const { offset } = graphicStructure;
				if (componentStructure.offset === null) {
					throw new ManipulatorError('component offset is null');
				}

				const parentGraphic = parentComponentGraphics[offset + componentStructure.offset];
				if (parentGraphic === undefined) {
					throw new ManipulatorError('parent graphic not found', {
						parentComponentStructure: parentComponent.getStructure(),
						componentStructure,
					});
				}
				const parentGraphicElement = parentGraphic.getGraphicElement();

				const graphicElement = graphic.getFrameElement();

				graphic.setStructure(() => graphicStructure);
				component.appendGraphic(graphic);
				parentGraphicElement.append(graphicElement);
			});

			component.setStructure(_ => componentStructure);

			parentComponent.appendComponent(component);

			if (componentStructure.components !== null) {
				this.recursiveInitializeAppendComponent(component, componentStructure.components);
			}
		});
	};

	/**
	 * Синхронизирует положение графики в DOM по значению её офсета и
	 * возвращает вычисленную родительскую графику для переданной графики.
	 * @param graphic Синхронизируемая графика.
	 */
	protected syncGraphicOffsetToDOM = (graphic: IGraphic): IGraphic => {
		if (graphic.type === GraphicType.PAGE) {
			throw new ManipulatorError('graphic is page');
		}

		const frameElement = graphic.getFrameElement();
		const component = graphic.getParentComponent();
		if (component === null) {
			throw new ManipulatorError('component is null');
		}
		const graphics = component.getGraphics();
		const graphicIndex = graphics.indexOf(graphic);
		if (graphicIndex === -1) {
			throw new ManipulatorError('invalid graphic');
		}
		const componentOffset = component.getOffset();
		if (componentOffset === null) {
			throw new ManipulatorError('component offset is null');
		}
		const parentComponent = component.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}
		const parentGraphics = parentComponent.getGraphics();
		const parentGraphic = parentGraphics[componentOffset + graphicIndex];
		if (parentGraphic === undefined) {
			throw new ManipulatorError('parent graphic not found');
		}
		const parentElement = parentGraphic.getFrameElement();

		if (parentElement.contains(frameElement)) {
			return parentGraphic;
		}

		parentElement.append(frameElement);

		return parentGraphic;
	};

	private recursiveAppendComponent = (parentComponent: IComponent, structure: AnyComponentStructure): IComponent => {
		const component = this.dependencies.componentFactory.createComponent(structure);

		structure.graphics?.forEach(graphicStructure => {
			const graphic = this.dependencies.graphicFactory.createGraphic(graphicStructure.type, component);
			graphic.setStructure(() => graphicStructure);
			component.appendGraphic(graphic);
		});

		this.mutateByAppendComponent(parentComponent, component);

		structure.components?.forEach(internalComponentStructure => {
			this.recursiveAppendComponent(component, internalComponentStructure);
		});

		return component;
	};
}

export default MutableComponentTree;
