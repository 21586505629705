/* eslint no-underscore-dangle: 0 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import _ from 'lodash';
import parse, {
	getLength, Token, TokenFormat, TokenType,
} from '../parser';
import render, { dispatch } from '../render';
import type {
	IEditorEventDetails, IEditorOptions, Model, TextRange,
} from './types';
import {
	DiffActionType, DirtyState, EventName, FontFamily, FontSize, IPickLinkOptions, TextAlign,
} from './types';
import History, { IHistoryEntry } from './history';
import { getTextRange, rangeToLocation, setRange } from './range';
import {
	getText,
	insertText,
	replaceText,
	setFontColor,
	setFontFamily,
	setFontSize,
	setFormat,
	updateFromInputEvent,
	updateFromInputEventFallback,
	updateFromOldEvent,
} from './update';
import {
	cutText as plainCutText,
	ICutText,
	ITokenFormatUpdate,
	setLink,
	sliceTokens,
	TextRange as Rng,
} from '../formatted-string';
import Shortcuts from './shortcuts';
import {
	getInputText, isElement, isNotHaveSelection, startsWith,
} from './utils';
import toHTML from '../render/html';
import { objectMerge } from '../utils/objectMerge';
import { IMextTokenRange, ITokenStyle } from '../parser/types';
import { createToken, tokenRange } from '../formatted-string/utils';
import Utils from '../../../utils/impl/Utils';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';

const defaultPickLinkOptions: IPickLinkOptions = {
	url: cur => {
		// eslint-disable-next-line no-alert
		const res = prompt('Введите ссылку', cur);
		if (res !== null) {
			return res;
		}
		return '';
	},
};

/**
 * Editor - это основа текстового редактора. Его работа строится на следующей однонаправленной архитектуре:
 * 1) Есть модель данных (источник правды) Model. Это по сути просто массив токенов (токен - содержит минимально
 * необходимую информацию: тип, значение, шрифт, формат и тд). Токены представлены в системе как интерфейс Token.
 * Токены бывают разные: просто обычный текст либо какие-то префиксные токены (ссылка, упоминания, хештеги и т.д.).
 * 2) Модель является источником данных для рендеринга в представление (представление - это по сути то, как выглядят
 * данные в виде отформатированной строки).
 * 3) Модель предоставляет эти данные в поле ввода (элемент с атрибутом contenteditable) и в этом элементе мы считываем
 * ввод. Когда вводится текст, мы пропускаем его через парсер, получаем некую модель данных и эту модель накатываем
 * поверх того, что у нас уже есть.
 * Работа редактора строится на стандарте кодирования, преобразующий номера ячеек таблицы Юникод в бинарные коды с
 * использованием переменного количества бит:16 или 32 (стандарт UTF-16).
 *
 * Пример обработки ввода пользователем текста: onBeforeInput
 * 1) Ввод символа
 * 2) Первым делом срабатывает onBeforeInput() перед событием input принимая объект события, здесь надо обратить
 * внимание на метод updateFromInputEvent - он проверяет тип события, которое происходит
 * (вставка, форматирование и тд) и в случае стандартно ввода символов (стандартный ввод в контексте браузера)
 * истинная модель обновляется через replaceText(), он в свою очередь обновит модель через updateTokens().
 * В updateTokens запускается парсер, который определяет символы ввода пользователя и обновляет модель.
 * Каждое изменение модели вызывает рендер.
 * 3) Парсер: в него прилетает строка и истинная модель. Именно здесь мы можем сделать какие-то кастомные
 * обработки символов (напрмиер абзац) и поменять соответственно модель.
 */
export default class Editor {
	// eslint-disable-next-line no-use-before-define
	public shortcuts: Shortcuts<Editor>;
	public history: History<Model>;
	private isEditable = false;
	public _model: Model;

	private readonly postPasteEvents: (() => void)[];	// Функции, которые запустятся после вставки
	private readonly postInputEvent: (() => void)[];	// Функции, которые запустятся после ввода
	private readonly postChangeRangeEvent: (() => void)[];	// Функции, которые запустятся после ввода
	private readonly postChangeModelEvents: (() => void)[];	// Функции, которые запустятся после изменения модели
	/** Диапазон, который был на начало композиции */
	private compositionStartRange: TextRange | null = null;
	/** Диапазон композиции */
	private compositionRange: TextRange | null = null;
	private pendingModel: Model | null = null;
	private _inited = false;
	/** MIME-тип для хранения отформатированной строки в буфере */
	private fragmentMIME = 'web wakadoo/fragment';
	/** Редактор в «грязном» состоянии: требуется синхронизация модели и UI (> 0) */
	private dirty: DirtyState = DirtyState.None;
	/**
     * Текущая позиция каретки/выделения в редакторе.
	 * Должна быть источником правды при работе с выделением.
     */
	private caret: TextRange = [0, 0];
	private focused = false;
	private rafId = 0;
	/**
	 * Стили для отображения текста. Сохраняет стили при удалении последнего текстового токена в модели (чтобы
	 * пользователь начинал печатать после удаления текста с уже ранее выбранным форматированием). Изменяется в
	 * следующих случаях:
	 * 1) когда мы нажали на какую-либо кнопку форматирования текста (даже в пустом редакторе) - для того, чтобы
	 * пользователь начал печатать с установленным им форматом.
	 * 2) когда изменился диапазон (положение каретки) и стили отличаются
	 */
	private _tokenStyle: ITokenStyle = {
		fontSize: FontSize.Pt14,
		fontFamily: FontFamily.Default,
		color: '#fff',
		format: TokenFormat.None,
		textAlign: TextAlign.LEFT,
		lineHeight: 1,
	};

	/**
     * @param element - Контейнер, в котором будет происходить редактирование
     * @param options - Опции для настройки
     */
	constructor(public element: HTMLElement, public options: IEditorOptions) {
		this.postPasteEvents = [];
		this.postInputEvent = [];
		this.postChangeRangeEvent = [];
		this.postChangeModelEvents = [];
		const value = options.value || '';
		this.model = {
			id: Utils.Generate.UUID4(),
			tokens: parse(this.sanitizeText(value), options.parse),
			lineHeight: 1,
		};
		this.history = new History({
			compactActions: [DiffActionType.Insert, DiffActionType.Remove],
		});

		this.shortcuts = new Shortcuts<Editor>(this);
		this.setup();
		this.setSelection(value.length);
		this.history.push(this.model, 'init', this.caret);
		this._inited = true;
	}

	get model(): Model {
		return this._model;
	}

	set model(value: Model) {
		if (this._model !== value) {
			this._model = value;
			if (this.dirty === DirtyState.None) {
				this.dirty = DirtyState.Dirty;
			}
			this.scheduleSyncUI();
		}
	}

	get tokenStyle(): ITokenStyle {
		return this._tokenStyle;
	}

	set tokenStyle(value: ITokenStyle) {
		if (this._tokenStyle !== value) {
			this._tokenStyle = value;
		}
	}

	/**
	 * Возвращает массив объектов типа IMextTokenRange, в которые будут записаны токены, находящиеся в диапазоне
	 * выделения, а также информация о том, какая часть токена находится в диапазоне выделения (например когда выделение
	 * заканчивается на половине токена). При этом если редактор не в режиме редактирования, то считаем что выделены все
	 * токены и соответственно все попадут в IMextTokenRange.
	 */
	public getSelectedTokens = (): IMextTokenRange[] => {
		const result: IMextTokenRange[] = [];
		const tokens = this.getTokens();
		const selection = this.getSelection();

		if (!this.isEditable) {
			tokens.forEach(token => {
				result.push({
					token,
					start: 0,
					end: 0,
					isFull: true,
				});
			});
			return result;
		}

		let isSelect = false;
		let currentPosition = 0;
		for (let i = 0; i < tokens.length; i++) {
			const token = tokens[i];

			if (!isSelect) {
				if (selection[0] === currentPosition) {	// selection[0] - позиция начала выделения
					result.push({
						token,
						start: 0,
						end: 0,
						isFull: true,
					});
				}
				if (selection[0] > currentPosition && selection[0] < currentPosition + token.value.length) {
					result.push({
						end: currentPosition + token.value.length,
						start: selection[0] - currentPosition,
						isFull: false,
						token,
					});
				}
				isSelect = true;
			} else {
				if (selection[1] === currentPosition + token.value.length) {
					result.push({
						end: 0,
						start: 0,
						isFull: true,
						token,
					});
					break;
				}
				if (selection[1] > currentPosition && selection[1] < currentPosition + token.value.length) {
					result.push({
						start: 0,
						end: selection[1] - currentPosition,
						isFull: true,
						token,
					});
					break;
				}
			}

			currentPosition += token.value.length;
		}

		return result;
	};

	/**
	 * Настраивает редактор для работы. Вынесено в отдельный метод для удобного
	 * переопределения
	 */
	public setup(): void {
		const { element } = this;
		// this.enableEditable();
		element.translate = false;

		// Чек-лист для проверки ввода
		// * Пишем текст в позицию
		// * Выделяем текст и начинаем писать новый
		// * Удаление в пустой строке (Backspace)
		// * Долго зажимаем зажимаем клавишу (е → ё)
		// * Автозамена при написании текста (Safari)
		// * Пишем текст в китайской раскладке
		// * Автоподстановка слов (iOS, Android)
		// * Punto Switcher
		// * Изменение форматирования из тачбара на Маке
		// * Замена правописания
		// * Вставка преревода строки: Enter/Shift-Enter/Alt-Enter
		// * Баг в Хроме: ставим курсор в конец строки, Cmd+← переходим в начало,
		//   пишем букву, стираем следующую по Fn+Backspace. Хром посылает команду
		//   insertText: null, а не beforeinput: deleteContentForward
		// * В Windows при использорвании нативной панели с эмоджи, само эмоджи
		//   вставляется через два события input: insertCompositionText и insertText
		element.addEventListener('keydown', this.onKeyDown);
		element.addEventListener('compositionstart', this.onCompositionStart);
		element.addEventListener('compositionupdate', this.onCompositionUpdate);
		element.addEventListener('compositionend', this.onCompositionEnd);
		element.addEventListener('beforeinput', this.onBeforeInput);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		element.addEventListener('input', this.onInput);
		element.addEventListener('cut', this.onCut);
		element.addEventListener('copy', this.onCopy);
		// element.addEventListener('paste', this.onPaste);
		element.addEventListener('click', this.onClick);
		element.addEventListener('dblclick', this.onDoubleClick);
		element.addEventListener('focus', this.onFocus);
		element.addEventListener('blur', this.onBlur);

		const { shortcuts } = this.options;

		if (shortcuts) {
			this.shortcuts.registerAll(shortcuts);
		}
	}

	/**
	 * Вызывается для того, чтобы удалить все связи редактора с DOM.
	 */
	public dispose(): void {
		const { element } = this;

		element.removeEventListener('keydown', this.onKeyDown);
		element.removeEventListener('compositionstart', this.onCompositionStart);
		element.removeEventListener('compositionupdate', this.onCompositionUpdate);
		element.removeEventListener('compositionend', this.onCompositionEnd);
		element.removeEventListener('beforeinput', this.onBeforeInput);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		element.removeEventListener('input', this.onInput);
		element.removeEventListener('cut', this.onCut);
		element.removeEventListener('copy', this.onCopy);
		// element.removeEventListener('paste', this.onPaste);
		element.removeEventListener('click', this.onClick);
		element.removeEventListener('focus', this.onFocus);
		element.removeEventListener('blur', this.onBlur);
		document.removeEventListener('selectionchange', this.onSelectionChange);
	}

	/// //////// Публичные методы для работы с текстом ///////////

	/**
	 * Вставляет текст в указанную позицию
	 */
	// public insertText(pos: number, text: string): Model {
	// 	text = this.sanitizeText(text);
	// 	return this.updateModel(
	// 		insertText(this.model, pos, text, this.options),
	// 		DiffActionType.Insert,
	// 		[pos, pos + text.length],
	// 	);
	// }

	/**
	 * Удаляет указанный диапазон текста
	 */
	// public removeText(from: number, to: number): Model {
	// 	return this.updateModel(
	// 		removeText(this, from, to, this.options),
	// 		DiffActionType.Remove,
	// 		[from, to],
	// 	);
	// }

	/**
	 * Заменяет текст в указанном диапазоне `from:to` на новый
	 */
	public replaceText(from: number, to: number, text: string): Model {
		const result = this.paste(text, from, to);
		return result;
	}

	/**
	 * Вырезает фрагмент по указанному диапазону из модели и возвращает его
	 * @returns Вырезанный фрагмент модели
	 */
	public cut(from: number, to: number): Model {
		const result: ICutText = plainCutText(this, from, to, this.options.parse);
		const remaindModel = { ...this.model, tokens: result.tokens };
		this.updateModel(remaindModel, 'cut', [from, to]);
		return { ...this.model, tokens: result.cut };
	}

	/**
	 * Вставка текста в указанную позицию
	 */
	public paste(text: string | Token[], from: number, to: number): Model {
		text = this.sanitizeText(text);
		const nextModel = replaceText(this, text, from, to, this.options);
		const len = typeof text === 'string' ? text.length : getLength(text);
		const pos = from + len;
		return this.updateModel(nextModel, 'paste', [pos, pos]);
	}

	/**
	 * Ставит фокус в редактор
	 */
	public focus(): void {
		const [from, to] = this.caret;
		this.element.focus();
		setRange(this.element, from, to);
	}

	/**
	 * Обновляет форматирование у указанного диапазона
	 */
	public updateFormat(format: TokenFormat | ITokenFormatUpdate, from: number, to = from): Model {
		const tokens = setFormat(this.model.tokens, format, from, to, this.options);
		const result = this.updateModel(
			{ ...this.model, tokens },
			'format',
			[from, to],
		);
		setRange(this.element, from, to);
		this.emit('editor-formatChange');
		return result;
	}

	/**
	 * Выбрать ссылку для указанного диапазона
	 */
	// public pickLink(options: IPickLinkOptions = defaultPickLinkOptions): void {
	// 	const [from, to] = options.range || this.getSelection();
	// 	let token = this.tokenForPos(from);
	// 	let currentUrl = '';
	//
	// 	if (token) {
	// 		if (token.format & TokenFormat.LinkLabel) {
	// 			// Это подпись к ссылке в MD-формате. Найдём саму ссылку
	// 			let ix = this.model.tokens.indexOf(token) + 1;
	// 			while (ix < this.model.tokens.length) {
	// 				token = this.model.tokens[ix++];
	// 				if (token.type === TokenType.Link) {
	// 					break;
	// 				}
	// 			}
	// 		}
	//
	// 		if (token.type === TokenType.Link) {
	// 			currentUrl = token.link;
	// 		}
	// 	}
	//
	// 	const result = options.url(currentUrl);
	// 	if (result && typeof result === 'object' && result.then) {
	// 		result.then(nextUrl => {
	// 			if (nextUrl !== currentUrl) {
	// 				this.setLink(nextUrl, from, to);
	// 			}
	// 		});
	// 	} else if (result !== currentUrl) {
	// 		this.setLink(result as string, from, to);
	// 	}
	// }

	/**
	 * Ставит ссылку на `url` на указанный диапазон.
	 * @param url Ссылка. Если `url` пустой или равен `null`, удаляет ссылку с указанного диапазона.
	 * @param from Начало диапазона.
	 * @param to Конец диапазона.
	 */
	public setLink(url: string | null, from: number, to = from): Model {
		if (url) {
			url = url.trim();
		}

		const range: Rng = [from, to - from];

		const updated: Model = {
			...this.model,
			tokens: setLink(this.model.tokens, url, range[0], range[1]),
		};

		return this.updateModel(updated, 'link');
	}

	/**
	 * Отменить последнее действие
	 */
	public undo(): IHistoryEntry<Model> | undefined {
		if (this.history.canUndo) {
			const entry = this.history.undo();
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.updateModel(entry.state, false);
			const { current } = this.history;
			if (current) {
				const range = current.caret || current.range;
				if (range) {
					this.setSelection(range[0], range[1]);
				}
			}
			return entry;
		}
		return undefined;
	}

	/**
	 * Повторить последнее отменённое действие
	 */
	public redo(): IHistoryEntry<Model> | undefined {
		if (this.history.canRedo) {
			const entry = this.history.redo();
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.updateModel(entry.state, false);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const range = entry.caret || entry.range;
			if (range) {
				this.setSelection(range[0], range[1]);
			}
			return entry;
		}
		return undefined;
	}

	public getCopyModel(): Model {
		return _.cloneDeep(this._model);
	}

	public getTokens(): Token[] {
		return this.model.tokens;
	}

	/**
	 * Возвращает фрагмент модели для указанного диапазона
	 */
	public slice(from: number, to?: number): Model {
		return {
			...this.model,
			tokens: sliceTokens(this.model.tokens, from, to),
		};
	}

	/**
	 * Возвращает токен для указанной позиции
	 * @param tail В случае, если позиция `pos` указывает на границу токенов,
	 * при `tail: true` вернётся токен слева от границы, иначе справа
	 */
	public tokenForPos(pos: number, tail?: boolean): Token | undefined {
		let offset = 0;
		let len = 0;
		const { model } = this;
		for (let i = 0, token: Token; i < model.tokens.length; i++) {
			token = model.tokens[i];
			len = offset + token.value.length;
			if (pos >= offset && (tail ? pos <= len : pos < len)) {
				return token;
			}
			offset += token.value.length;
		}

		if (offset === pos) {
			// Указали самый конец строки — вернём последний токен
			return model.tokens[model.tokens.length - 1];
		}
		return undefined;
	}

	/**
	 * Возвращает текущее выделение в виде текстового диапазона
	 */
	public getSelection(): TextRange {
		return this.caret;
	}

	/**
	 * Указывает текущее выделение текста или позицию каретки
	 */
	public setSelection(from: number, to = from): void {
		[from, to] = this.normalizeRange([from, to]);
		this.saveSelection([from, to]);
		if (!this.dirty) {
			setRange(this.element, from, to);
		}
	}

	/**
	 * Заменяет текущее значение редактора на указанное. При этом полностью
	 * очищается история изменений редактора
	 */
	public setValue(value: string | Token[], selection?: TextRange): void {
		if (typeof value === 'string') {
			value = parse(this.sanitizeText(value), this.options.parse);
		}

		if (!selection) {
			const len = getText(value).length;
			selection = [len, len];
		}

		this.saveSelection(selection);
		this.model.tokens = value;

		this.history.clear();
		this.history.push(this.model, 'init', this.caret);
	}

	/**
	 * Обновляет опции редактора
	 */
	public setOptions(options: Partial<IEditorOptions>): void {
		if (options.shortcuts) {
			this.shortcuts.unregisterAll();
			this.shortcuts.registerAll(options.shortcuts);
		}

		this.options = objectMerge(this.options, options);

		this.render();
	}

	public setParagraphWithRedLine = () => {
		this.setParagraph();
		this.setRedLine();
	};

	public setParagraph = () => {
		/* Переводы строк и тп считаются за символ и участвуют в подсчете позиции */
		const [from, to] = getTextRange(this.element);

		/* При переходе на новый абзац мы добавляем отступ (символ горизонтальной табуляции) */
		const text = '§';

		/* Если есть выделение текста - то при добавлении параграфа мы должны удалить выделение */
		if (from !== to) {
			const nextModel = replaceText(this, text, from, to, this.options);
			this.updateModel(nextModel, 'setParagraph');
			this.setSelection(to, to);
		} else {
			// const nextModel = insertText(copiedModel, from, text, this.options);
			const nextModel = insertText(this, from, text, this.options);
			this.updateModel(nextModel, 'setParagraph');

			// Для абзаца без красной строки
			this.setSelection(to + 1, to + 1);
			this.saveSelection([to + 1, to + 1]);
			// Для абзаца с красной строкой
			// this.setSelection(to + 2, to + 2);
		}

		this.dirty = DirtyState.DirtyRetainNewline;
		this.emit('editor-update');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает красную строку (отступ после абзаца). Здесь важно понимать, что мы ставим красную
	 * строку только программно (после абзаца запускаем данный метод), следовательно, диапазона
	 * не будет (каретка всегда будет после символа абзаца, вернее после <br>).
	 */
	public setRedLine = () => {
		const [from, to] = getTextRange(this.element);
		const text = '\t';

		/* from + 1 здесь по той причине, что рендер еще не прошел и getTextRange не учитывает наш абзац */
		const nextModel = insertText(this, from + 1, text, this.options);
		this.updateModel(nextModel, 'setRedLine');

		this.setSelection(from + 2, from + 2);

		this.dirty = DirtyState.DirtyRetainNewline;
		this.emit('editor-update');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает шрифт заданному диапазону текста. Если диапазон не задан, то устанавливает выбранный шрифт всей
	 * модели.
	 */
	public setFontFamily = (fontFamily: FontFamily, from?: number, to?: number): void => {
		if (from === undefined) {
			// Получаем диапазон или (если нет) положение каретки
			[from, to] = this.getSelection();
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		let { model } = this;
		let selectedRange: Token[] | undefined;
		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontFamily(this.model.tokens, fontFamily, from, to, this.options),
				};
			}
			this.updateModel(model, 'fontFamily', [from, to]);

			// 	Меняем размер текста у всей модели
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken(
						'',
						undefined,
						undefined,
						fontFamily,
						undefined,
						undefined,
						true,
					)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.fontFamily === fontFamily) {
						accumulator.push(token);
						return accumulator;
					}
					const newToken = {
						...token,
						fontFamily,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}
			this.updateModel(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	public setFontFamilyWithoutPostEvents = (fontFamily: FontFamily, from?: number, to?: number): void => {
		if (from === undefined) {
			// Получаем диапазон или (если нет) положение каретки
			[from, to] = this.getSelection();
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		let { model } = this;
		let selectedRange: Token[] | undefined;
		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontFamily(this.model.tokens, fontFamily, from, to, this.options),
				};
			}
			this.updateModel(model, 'fontFamily', [from, to]);

			// 	Меняем размер текста у всей модели
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken(
						'',
						undefined,
						undefined,
						fontFamily,
						undefined,
						undefined,
						true,
					)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.fontFamily === fontFamily) {
						accumulator.push(token);
						return accumulator;
					}
					const newToken = {
						...token,
						fontFamily,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}
			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	public setFontSize = (fontSize: FontSize, from?: number, to?: number): void => {
		// Получаем диапазон или (если нет) положение каретки
		if (from === undefined) {
			[from, to] = this.getSelection();
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		let { model } = this;
		let selectedRange: Token[] | undefined;
		/* Если есть диапазон выделения - работаем с этим диапазоном */
		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontSize(this.model.tokens, fontSize, from, to, this.options),
				};
			}
			this.updateModel(model, 'fontSize', [from, to]);

			// 	Меняем размер текста у всей модели
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken('', undefined, undefined, undefined, fontSize, undefined, true)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.fontSize === fontSize) {
						accumulator.push(token);
						return accumulator;
					}
					const newToken = {
						...token,
						fontSize,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}
			this.updateModel(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	public setFontSizeWithoutPostEvents = (fontSize: FontSize, from?: number, to?: number): void => {
		// Получаем диапазон или (если нет) положение каретки
		if (from === undefined) {
			[from, to] = this.getSelection();
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		let { model } = this;
		let selectedRange: Token[] | undefined;
		/* Если есть диапазон выделения - работаем с этим диапазоном */
		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontSize(this.model.tokens, fontSize, from, to, this.options),
				};
			}
			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize', [from, to]);

			// 	Меняем размер текста у всей модели
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken('', undefined, undefined, undefined, fontSize, undefined, true)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.fontSize === fontSize) {
						accumulator.push(token);
						return accumulator;
					}
					const newToken = {
						...token,
						fontSize,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}
			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает цвет выделенного текста, либо если нет диапазона выделения - устанавливает цвет у всей модели.
	 */
	public setColor = (color: string, from?: number, to?: number): void => {
		// Получаем диапазон или (если нет) положение каретки
		const range: TextRange = this.getSelection();
		if (from === undefined) {
			[from, to] = range;
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		// let { model } = this;
		let model = this.getCopyModel();
		let selectedRange: Token[] | undefined;
		/* Если есть диапазон выделения - работаем с этим диапазоном */

		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontColor(this.model.tokens, color, from, to, this.options),
				};
			}

			this.updateModel(model, 'fontSize', [from, to]);

			/* 	Меняем цвет текста у всей модели, потому что нет диапазона выделения (в каком-то месте стоит
				 каретка (режим редактирования без выделения текста) или текстовая графика в фокусе без режима
				  редактирования). */
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken('', undefined, undefined, undefined, undefined, color, true)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.color === color) {
						accumulator.push(token);
						return accumulator;
					}

					const newToken = {
						...token,
						color,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}

			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	public setColorWithoutPostEvents = (color: string, from?: number, to?: number): void => {
		// Получаем диапазон или (если нет) положение каретки
		const range: TextRange = this.getSelection();
		if (from === undefined) {
			[from, to] = range;
		} else if (to === undefined) {
			to = from;
		}

		// Получаем обновлённую модель
		// let { model } = this;
		let model = this.getCopyModel();
		let selectedRange: Token[] | undefined;
		/* Если есть диапазон выделения - работаем с этим диапазоном */

		if (from !== to) {
			selectedRange = sliceTokens(this.model.tokens, from, to);
			if (selectedRange) {
				model = {
					...model,
					tokens: setFontColor(this.model.tokens, color, from, to, this.options),
				};
			}

			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize', [from, to]);

			/* 	Меняем цвет текста у всей модели, потому что нет диапазона выделения (в каком-то месте стоит
				 каретка (режим редактирования без выделения текста) или текстовая графика в фокусе без режима
				  редактирования). */
		} else {
			if (!this.model.tokens.length) {
				// Пограничный случай: выставляем размер пустой строке
				model = {
					...this.model,
					tokens: [createToken('', undefined, undefined, undefined, undefined, color, true)],
				};
			} else {
				const newTokens = this.getTokens().reduce((accumulator, token): Token[] => {
					if (token.color === color) {
						accumulator.push(token);
						return accumulator;
					}

					const newToken = {
						...token,
						color,
					};
					accumulator.push(newToken);
					return accumulator;
				}, [] as Token[]);

				model = {
					...this.model,
					tokens: newTokens,
				};
			}

			this.updateModelWithoutCallPostChangeModelEvents(model, 'fontSize');
		}

		setRange(this.element, from, to);
		this.emit('editor-fontSizeChange');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает выравнивание всем токенам модели.
	 * Среднее время выполнения 3 мс.
	 * @param align
	 */
	public setAlign = (align: TextAlign) => {
		// eslint-disable-next-line array-callback-return
		const newTokens: Token[] = this.getTokens().map((token) => ({
			...token,
			textAlign: align,
		}));
		const model: Model = {
			...this.model,
			tokens: newTokens,
		};

		this.updateModel(model, 'textAlign');

		// setRange(this.element, from, to);
		this.emit('editor-textAlignChange');
		this.callPostInputEvent();
	};

	public setAlignWithoutPostEvents = (align: TextAlign) => {
		// eslint-disable-next-line array-callback-return
		const newTokens: Token[] = this.getTokens().map((token) => ({
			...token,
			textAlign: align,
		}));
		const model: Model = {
			...this.model,
			tokens: newTokens,
		};

		this.updateModelWithoutCallPostChangeModelEvents(model, 'textAlign');

		// setRange(this.element, from, to);
		this.emit('editor-textAlignChange');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает выравнивание переданным токенам, что дает возможность выравнивания токенов построчно, а не для
	 * всего текстового редактора. Как работает: когда находимся в режиме редактирования - ищем все токены
	 * на одной строке, передаем их индексы (tokensIndexes) и им устанавливаем align.
	 * @param align - параметр для выравнивания.
	 * @param tokensIndexes - индексы токенов для выравнивания.
	 */
	public setAlignAtEditableEditor = (align: TextAlign, tokensIndexes: Token[]) => {
		tokensIndexes.forEach((token) => {
			token.textAlign = align;
		});
		let { model } = this;
		model = {
			...this.model,
		};

		this.updateModel(model, 'textAlign');

		// setRange(this.element, from, to);
		this.emit('editor-textAlignChange');
		this.callPostInputEvent();
	};

	/**
	 * Устанавливает высоту строки.
	 * @param lineHeight
	 */
	public setLineHeightWithoutPostEvents = (lineHeight: number) => {
		// Получаем диапазон или (если нет) положение каретки
		const range: TextRange = this.getSelection();
		let from;
		let to;
		// eslint-disable-next-line prefer-const
		[from, to] = range;

		let newTokens: Token[];
		// Пользователь выделил текст
		if (from !== to) {
			const len = to - from;
			const [start, end] = tokenRange(this.model.tokens, from, to);
			newTokens = this.getTokens().map((token, index) => {
				if (index >= start.index && index <= end.index) {
					return {
						...token,
						lineHeight,
					};
				}
				return token;
			});
		} else {
			newTokens = this.getTokens().map(token => ({
				...token,
				lineHeight,
			}));
		}
		const newModel = {
			...this.model,
			tokens: newTokens,
		};

		this.updateModelWithoutCallPostChangeModelEvents(newModel, 'lineHeight');

		// setRange(this.element, from, to);
		this.emit('editor-lineHeightChange');
		this.callPostInputEvent();
	};

	public setLineHeight = (lineHeight: number) => {
		// Получаем диапазон или (если нет) положение каретки
		const range: TextRange = this.getSelection();
		let from;
		let to;
		// eslint-disable-next-line prefer-const
		[from, to] = range;

		let newTokens: Token[];
		// Пользователь выделил текст
		if (from !== to) {
			const len = to - from;
			const [start, end] = tokenRange(this.model.tokens, from, to);
			newTokens = this.getTokens().map((token, index) => {
				if (index >= start.index && index <= end.index) {
					return {
						...token,
						lineHeight,
					};
				}
				return token;
			});
		} else {
			newTokens = this.getTokens().map(token => ({
				...token,
				lineHeight,
			}));
		}
		const newModel = {
			...this.model,
			tokens: newTokens,
		};

		this.updateModel(newModel, 'lineHeight');

		// setRange(this.element, from, to);
		this.emit('editor-lineHeightChange');
		this.callPostInputEvent();
	};

	/**
	 * Возвращает токены, находящиеся на одной строке с кареткой. Соответственно редактор должен быть в режиме
	 * редактирования.
	 */
	public getTokensCurrentFocusRow = (): Token[] | null => {
		if (!this.isActiveEditable()) return null;
		const range: TextRange = this.getSelection();
		const [from, to] = range;
		// Находим индексы токенов в диапазоне выделения
		const [start, end] = tokenRange(this.model.tokens, from, to);
		if (start.index === -1 || end.index === -1 || end.index < start.index) {
			// Невалидные данные, ничего не делаем
			// return;
		}

		const result: Token[] | null = [];

		/* Проверим случай, когда диапазон начинается с самого начала текста */
		if (start.index === 0) {
			let i = 0;
			/* сначала добавим все токены из диапазона выделения */
			while (i < end.index) {
				result.push(this.model.tokens[i]);
				i++;
			}
			i = end.index;
			while (i < this.model.tokens.length && this.model.tokens[i].type !== TokenType.Newline) {
				result.push(this.model.tokens[i]);
				i++;
			}
		} else if (start.index !== 0) {
			let i = start.index;
			const tokens = this.getTokens();
			while (i >= 0
				&& (tokens[i].type !== TokenType.Newline
				|| tokens[i].type !== TokenType.Paragraph)
			) {
				result.push(tokens[i]);
				i--;
			}
			if (start.index !== end.index) {
				i = start.index;
				while (i < end.index) {
					result.push(this.model.tokens[i]);
					i++;
				}
				i = end.index;
				/* Найдем токены строки от конца диапазона до TokenType.Newline */
				while (i < this.model.tokens.length && this.model.tokens[i].type !== TokenType.Newline) {
					result.push(this.model.tokens[i]);
					i++;
				}
			}
		}
		return result;
	};

	/**
	 * Вспомогательный метод для вставки скопированного текста. Передаём в неё текст из буфера и опции, на выходе
	 * получим массив токенов.
	 * @param {DataTransfer} data -
	 * @param {IEditorOptions} options
	 * @returns {Token[] | undefined}
	 */
	// public getFormattedString = (data: DataTransfer, options: IEditorOptions): Token[] | undefined => {
	// 	const internalData = data.getData(this.fragmentMIME);
	//
	// 	if (internalData) {
	// 		if (typeof internalData === 'string') {
	// 			const sinitizeString = sanitize(internalData);
	// 			return JSON.parse(sinitizeString) as Token[];
	// 		}
	// 		return internalData;
	// 	}
	//
	// 	if (options.html) {
	// 		// Обработка пограничного случая: MS Edge при копировании из адресной строки
	// 		// добавляет ещё и HTML. В итоге просто так вставить ссылку не получится.
	// 		// Поэтому мы сначала проверим plain text: если это ссылка, то оставим её
	// 		// как есть, без парсинга HTML.
	// 		const plain = parse(sanitize(data.getData('text/plain') || ''), options.parse);
	// 		if (plain.length === 1 && plain[0].type === TokenType.Link) {
	// 			return plain;
	// 		}
	//
	// 		const html = data.getData('text/html');
	// 		if (html) {
	// 			return parseHTML(sanitize(html), { links: options.htmlLinks });
	// 		}
	// 	}
	// 	return undefined;
	// };

	/**
	 * То же самое, что `setValue`, но без отправки событий об изменении контента
	 */
	// public replaceValue(value: string | Token[], selection?: TextRange): void {
	// 	this._inited = false;
	// 	this.setValue(value, selection);
	// 	this._inited = true;
	// }

	/**
	 * Возвращает текущее текстовое значение модели редактора
	 */
	// public getText(tokens = this.model.tokens): string {
	// 	return getText(tokens);
	// }

	/**
	 * Возвращает строковое содержимое поля ввода
	 */
	public getInputText(): string {
		return getInputText(this.element);
	}

	/**
	 * Подписываемся на указанное событие
	 */
	public on(
		eventType: EventName,
		// eslint-disable-next-line no-undef
		listener: EventListenerOrEventListenerObject,
		// eslint-disable-next-line no-undef
		options?: boolean | AddEventListenerOptions,
	): this {
		this.element.addEventListener(eventType, listener, options);
		return this;
	}

	/**
	 * Отписываемся от указанного события
	 */
	public off(
		eventType: EventName,
		// eslint-disable-next-line no-undef
		listener: EventListenerOrEventListenerObject,
		// eslint-disable-next-line no-undef
		options?: boolean | AddEventListenerOptions,
	): this {
		this.element.removeEventListener(eventType, listener, options);
		return this;
	}

	/**
	 * Правильно помещает фрагмент текста в буффер. Вместе с обычным текстом
	 * туда помещается сериализованный фрагмент модели, чтобы сохранить форматирование
	 */
	public copyFragment(clipboard: DataTransfer, cut?: boolean): boolean {
		const range = getTextRange(this.element);

		if (range && !isNotHaveSelection(range)) {
			const fragment = cut
				? this.cut(range[0], range[1])
				: this.slice(range[0], range[1]);

			clipboard.setData('text/plain', getText(fragment.tokens));
			clipboard.setData('text/html', toHTML(fragment.tokens));

			if (cut) {
				this.setSelection(range[0]);
			}

			return true;
		}

		return false;
	}

	/**
	 * Копирование текста в системный буфер
	 */
	public copy(): boolean {
		console.log('cp');
		const range = getTextRange(this.element);

		if (range && !isNotHaveSelection(range)) {
			const fragment = this.slice(range[0], range[1]);
			const textPlain = getText(fragment.tokens);
			const textHtml = toHTML(fragment.tokens);
			const textFormat = `TEXT:${JSON.stringify(fragment.tokens)}`;

			const blob = new Blob([textFormat], { type: this.fragmentMIME });
			const blob2 = new Blob([textPlain], { type: 'text/plain' });
			const blob3 = new Blob([textHtml], { type: 'text/html' });
			const data4 = [new ClipboardItem({
				[this.fragmentMIME]: blob,
				'text/plain': blob2,
				'text/html': blob3,
			})];
			navigator.clipboard.write(data4);
			return true;
		}
		return false;
	}

	/**
	 * Вставка текста из системного буфера.
	 * Есть 2 варианта вставки: с сохранением исходного форматирования копируемого текста (ctrl + v)
	 * и без (plain text), когда вставляемый текст принимает форматирование того текста, в который он
	 * вставляется - в этом случае мы ориентируемся на токен перед кареткой.
	 *
	 */
	public onPaste(copiedTokens: Token[]): void {
		const range = getTextRange(this.element);
		const [from, to] = range;
		const tokens = this.getTokens();

		/* В проекте вставляемый из буфера текст принимает форматирование токена (при его наличии) рядом с которым он
		вставляется. Правила применения форматирования для вставляемого из буфера текста:
		мы смотрим диапазон выделения и берем первый токен из него как прототип, в случае если диапазон нулевой
		(пользователь не выделил текст) нужно ориентироваться на каретку и найти токен слева от каретки -
		он станет прототипом. В случае отсутствия токена слева, мы ищем токен справа от каретки. В случае отсутствия
		текстовых токенов - вставляется дефолтное форматирование текста. */
		let prototypeToken: Token | undefined;
		if	(from !== to) {
			const targetToken = this.tokenForPos(from);
			if	(!targetToken) {
				throw new ManipulatorError('not target token');
			}
			prototypeToken = targetToken;
		// 	Проверяем что есть какие-либо символы, с которых можем взять форматирование
		} else if (
			from === to
			&& tokens[0].value
		) {
			let targetToken = this.tokenForPos(from, true);
			prototypeToken = targetToken;
			/* Пограничный случай, когда мы взяли токен слева от каретки, но он заканчивается на пробел и в этом
			* случае мы должны проверить токен справа, так как форматирование должны привязать к не пробельным
			* символам */
			if	(targetToken) {
				if (targetToken.value[targetToken.value.length - 1] === ' '
				|| targetToken.type !== TokenType.Text
				) {
					targetToken = this.tokenForPos(from);
					if	(targetToken
						&& targetToken.value[0] !== ' '
						&& targetToken.type === TokenType.Text
					) {
						prototypeToken = targetToken;
					} else {
						prototypeToken = undefined;
					}
				}
			}

			/* В случае отсутствия символов, оставляем дефолтное форматирование */
		} else {
			prototypeToken = undefined;
		}

		try {
			if (copiedTokens) {
				const text: string | Token[] = this.sanitizeText<string | Token[]>(copiedTokens);
				let nextModel: Model;
				if	(prototypeToken) {
					nextModel = replaceText(
						this,
						text,
						from,
						to,
						{
							...this.options,
							parse: {
								prototypeToken,
							},
						},
					);
				} else {
					nextModel = replaceText(
						this,
						text,
						from,
						to,
						this.options,
					);
				}

				const length = typeof text === 'string' ? text.length : getLength(text);
				const pos = from + length;
				this.updateModel(nextModel, 'paste', [pos, pos]);
				this.setSelection(from + length);

				requestAnimationFrame(() => retainNewlineInViewport(this.getScroller()));

				setTimeout(() => {
					this.callPostPasteEvents();
				}, 0);
			} else {
				throw new Error('mimeType not supported.');
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log(error.message);
		}
	}

	/**
	 * Проверяет редактор на режим редактирования.
	 * @returns {boolean}
	 */
	public isActiveEditable = (): boolean => this.isEditable;

	/**
	 * Добавляет функцию в массив функций, вызываемых после изменения модели.
	 * @param {() => void} fn - Какая-либо функция, которая вызовется после изменения модели (ввод
	 * текста, вставка, удаление и тд).
	 */
	public addPostChangeModelEvent = (fn: () => void): void => {
		this.postChangeModelEvents.push(fn);
	};

	/**
	 * Добавляет функцию в массив функций, вызываемых после вставки (ctrl + V).
	 * @param {() => void} fn - Какая-либо функция, которая вызовется после вставки (ctrl + V).
	 */
	public addPostPasteEvent = (fn: () => void): void => {
		this.postPasteEvents.push(fn);
	};

	/**
	 * Добавляет функцию в массив функций, вызываемых после ввода.
	 * @param {() => void} fn - Какая-либо функция, которая вызовется после ввода.
	 */
	public addPostInputEvent = (fn: () => void): void => {
		this.postInputEvent.push(fn);
	};

	/**
	 * Добавляет функцию в массив функций, вызываемых после ввода.
	 * @param {() => void} fn - Какая-либо функция, которая вызовется после ввода.
	 */
	public addPostChangeRangeEvent = (fn: () => void): void => {
		this.postChangeRangeEvent.push(fn);
	};

	/**
	 * Переключает указанный формат у заданного диапазона текста
	 */
	public toggleFormat(format: TokenFormat, from?: number, to?: number): Model {
		/* Определяем диапазон выделения текста */
		const range: TextRange = this.getSelection();
		if (from == null) {
			[from, to] = range;
		} else if (to == null) {
			to = from;
		}

		/* Создаём новую модель в зависимости от диапазона выделения и его формата */
		let { model } = this;
		// let model = _.cloneDeep(this.model);
		/*  Если есть диапазон выделения */
		if (from !== to) {
			/* Получаем токены из диапазона выделения (может быть один или несколько) */
			const fragment: Token[] = sliceTokens(model.tokens, from, to);

			/* Если во фрагменте один токен - определяем форматирование и меняем на противоположное */
			if (fragment.length === 1) {
				const update: ITokenFormatUpdate = fragment[0].format & format
					// Если переданы одинаковые форматы
					? { remove: format }
					// Если переданы разные форматы
					: { add: format };

				model = {
					...model,
					tokens: setFormat(model.tokens, update, from, to, this.options),
				};

			/* Если во фрагменте несколько токенов и все токены имеют тот же формат, который мы передаем - меняем
			 формат у всего фрагмента */
			} else if (fragment.length > 1 && fragment.every((token) => token.format === format)) {
				// const tokens =
				model = {
					...model,
					tokens: setFormat(
						model.tokens,
						{ remove: format },
						from,
						to,
						this.options,
					),
				};
			/* Если во фрагменте несколько токенов и не все "жирные" - весь фрагмент делаем "жирным" */
			} else {
				model = {
					...model,
					tokens: setFormat(
						model.tokens,
						{ add: format },
						from,
						to,
						this.options,
					),
				};
			}
		/* Нет диапазона выделения и в хотя бы в одном токене нет переданного формата (то есть понимаем что не все
		 токены имеют переданный формат, чтобы убирать его у всей модели) */
		} else if (model.tokens.some((token) => !(token.format & format))) {
			const tokens = this.getTokens().map((token) => {
				let tokenFormat = token.format;
				tokenFormat |= format;
				const newToken = {
					...token,
					format: tokenFormat,
				};
				return newToken;
			});
			model = {
				...model,
				tokens,
			};
		} else if (model.tokens.every((token) => (token.format & format))) {
			const tokens = this.getTokens().map((token) => {
				let tokenFormat = token.format;
				tokenFormat &= ~format;
				const newToken = {
					...token,
					format: tokenFormat,
				};
				return newToken;
			});
			model = {
				...model,
				tokens,
			};
		}

		const result = this.updateModel(
			model,
			'format',
			[from, to],
		);

		setRange(this.element, from, to);
		this.emit('editor-formatChange');
		return result;
	}

	/**
	 * Переключает указанный формат у заданного диапазона текста без уведомление подписчиков
	 */
	public toggleFormatWithoutPostEvents(format: TokenFormat, from?: number, to?: number): Model {
		/* Определяем диапазон выделения текста */
		const range: TextRange = this.getSelection();
		if (from == null) {
			[from, to] = range;
		} else if (to == null) {
			to = from;
		}

		/* Создаём новую модель в зависимости от диапазона выделения и его формата */
		let { model } = this;
		// let model = _.cloneDeep(this.model);
		/*  Если есть диапазон выделения */
		if (from !== to) {
			/* Получаем токены из диапазона выделения (может быть один или несколько) */
			const fragment: Token[] = sliceTokens(model.tokens, from, to);

			/* Если во фрагменте один токен - определяем форматирование и меняем на противоположное */
			if (fragment.length === 1) {
				const update: ITokenFormatUpdate = fragment[0].format & format
					// Если переданы одинаковые форматы
					? { remove: format }
					// Если переданы разные форматы
					: { add: format };

				model = {
					...model,
					tokens: setFormat(model.tokens, update, from, to, this.options),
				};

				/* Если во фрагменте несколько токенов и все токены имеют тот же формат, который мы передаем - меняем
                 формат у всего фрагмента */
			} else if (fragment.length > 1 && fragment.every((token) => token.format === format)) {
				// const tokens =
				model = {
					...model,
					tokens: setFormat(
						model.tokens,
						{ remove: format },
						from,
						to,
						this.options,
					),
				};
				/* Если во фрагменте несколько токенов и не все "жирные" - весь фрагмент делаем "жирным" */
			} else {
				model = {
					...model,
					tokens: setFormat(
						model.tokens,
						{ add: format },
						from,
						to,
						this.options,
					),
				};
			}
			/* Нет диапазона выделения и в хотя бы в одном токене нет переданного формата (то есть понимаем что не все
             токены имеют переданный формат, чтобы убирать его у всей модели) */
		} else if (model.tokens.some((token) => !(token.format & format))) {
			const tokens = this.getTokens().map((token) => {
				let tokenFormat = token.format;
				tokenFormat |= format;
				const newToken = {
					...token,
					format: tokenFormat,
				};
				return newToken;
			});
			model = {
				...model,
				tokens,
			};
		} else if (model.tokens.every((token) => (token.format & format))) {
			const tokens = this.getTokens().map((token) => {
				let tokenFormat = token.format;
				tokenFormat &= ~format;
				const newToken = {
					...token,
					format: tokenFormat,
				};
				return newToken;
			});
			model = {
				...model,
				tokens,
			};
		}

		const result = this.updateModelWithoutCallPostChangeModelEvents(
			model,
			'format',
			[from, to],
		);

		setRange(this.element, from, to);
		this.emit('editor-formatChange');
		return result;
	}

	public enableEditable = () => {
		this.isEditable = true;
		this.element.contentEditable = 'true';
	};

	public disableEditable = () => {
		this.isEditable = false;
		this.caret = [0, 0];
		this.element.removeAttribute('contentEditable');
	};

	private onKeyDown = (evt: KeyboardEvent) => {
		if (!evt.defaultPrevented) {
			this.shortcuts.handle(evt);
		}

		// this.handleEnter(evt);
	};

	/* События композиции предоставляют средства для ввода текста дополнительным или альтернативным способом, а не
	 событиями клавиатуры, чтобы разрешить использование символов, которые могут быть недоступны на клавиатуре.
	 Пример это расширение браузера для ввода например китайских символов. */
	private onCompositionStart = (): void => {
		const textRange = getTextRange(this.element);
		if (textRange !== undefined) {
			this.compositionStartRange = textRange;
		} else {
			this.compositionStartRange = null;
		}

		this.compositionRange = null;
	};

	private onCompositionUpdate = () => {
		this.compositionRange = this.getCompositionRange();
	};

	private onCompositionEnd = () => {
		this.compositionStartRange = null;
	};

	/**
	 * Срабатывает перед событием input принимая объект события
	 */
	private onBeforeInput = (evt: InputEvent) => {
		if (evt.inputType === 'historyUndo') {
			this.undo();
			evt.preventDefault();
			return;
		}

		if (evt.inputType === 'historyRedo') {
			this.redo();
			evt.preventDefault();
			return;
		}

		/* Переводы строк и тп считаются за символ и участвуют в подсчете позиции */
		let range: TextRange = getTextRange(this.element);
		/* Метод getTargetRanges() интерфейса InputEvent возвращает массив объектов StaticRange, на которые повлияет
		изменение DOM, если событие ввода не будет отменено. Тут уже есть и диапазон и данные, так как пользователь
		нажал на кнопку и сработало событие onBeforeInput, значит можем выдернуть данные с объекта события */
		if (evt.getTargetRanges) {
			const ranges = evt.getTargetRanges();
			if (ranges.length) {
				range = rangeToLocation(this.element, ranges[0] as Range);
			}
		}

		/** Накопленная модель */
		this.pendingModel = updateFromInputEvent(evt, this, range, this.options);
	};

	private onInput = (evt: InputEvent): void => {
		let nextModel: Model = {
			...this.model,
			tokens: [],
		};
		const range: TextRange = getTextRange(this.element);

		if (this.pendingModel) {
			nextModel = this.pendingModel;
			// В мобильном Хроме, если находимся в режиме композиции, Enter два раза
			// вызовет событие `input`, причём второе будет без `beforeinput`.
			// Поэтому не удаляем модель находясь в режиме композиции, пусть это
			// сделает обработчик `compositionend`
			if (!this.compositionStartRange) {
				this.pendingModel = null;
			}
		} else {
			const prevRange = this.compositionRange || this.caret;
			if (range) {
				nextModel = evt.inputType
					? updateFromInputEventFallback(evt, this, range, prevRange, this.options)
					: updateFromOldEvent(this.getInputText(), this, range, prevRange, this.options);
				this.compositionRange = null;
			}
		}

		// Обычное изменение, сразу применяем результат к UI
		if (nextModel) {
			this.updateModel(nextModel, getDiffTypeFromEvent(evt), range);
		}
		this.callPostInputEvent();
	};

	private onSelectionChange = () => {
		if (this.dirty !== DirtyState.None) {
			return;
		}

		const range = getTextRange(this.element);
		if (range) {
			this.saveSelection(range);
		}
		this.callPostChangeRangeEvent();
	};

	/**
     * Обработка события копирования текста
     */
	public onCopy = (evt: ClipboardEvent) => {
		// Работа через старое API - НЕ УДАЛЯТЬ
		// if (this.copyFragment(evt.clipboardData)) {
		// 	evt.preventDefault();
		// }
		if (this.copy()) {
			evt.preventDefault();
		}
	};

	/**
     * Обработка события вырезания текста
     */
	private onCut = (evt: ClipboardEvent) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (this.copyFragment(evt.clipboardData, true)) {
			evt.preventDefault();
		}
	};

	/**
     * НЕ УДАЛЯТЬ!!!! Здесь работа по старому API - она еще нужна
     */
	// private onPaste = (evt: ClipboardEvent) => {
	// 	evt.preventDefault();
	//
	// 	const buffer = evt.clipboardData;
	// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 	// @ts-ignore
	// 	if (isFilePaste(buffer)) {
	// 		return;
	// 	}
	//
	// 	const range = getTextRange(this.element);
	// 	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 	// @ts-ignore
	// 	const parsed = getFormattedString(buffer, this.options);
	// 	let fragment: string | Token[];
	// 	if (buffer !== null) {
	// 		fragment = parsed
	// 			|| sanitize(buffer.getData('text/plain') || '');
	// 	} else {
	// 		fragment = parsed
	// 			|| sanitize('');
	// 	}
	//
	// 	if (fragment && range) {
	// 		let len = 0;
	// 		if (typeof fragment === 'string') {
	// 			len = fragment.length;
	// 		} else {
	// 			len = getLength(fragment);
	// 			if (last(fragment)?.format) {
	// 				/* У последнего токена есть форматирование: добавим sticky-токен,
	// 				чтобы пользователь продолжал писать в том же формате, что и был */
	// 				fragment.push({
	// 					fontFamily: FontFamily.Rubik,
	// 					fontSize: FontSize.Pt14,
	// 					color: '#000000',
	// 					textAlign: TextAlign.LEFT,
	// 					type: TokenType.Text,
	// 					value: '',
	// 					format: getFormat(this.model.tokens, range[0]),
	// 					lineHeight: 1,
	// 					sticky: true,
	// 				});
	// 			}
	// 		}
	//
	// 		this.paste(fragment, range[0], range[1]);
	// 		this.setSelection(range[0] + len);
	//
	// 		requestAnimationFrame(() => retainNewlineInViewport(this.getScroller()));
	//
	// 		setTimeout(() => {
	// 			this.callP	ostPasteEvents();
	// 		}, 0);
	// 	}
	// };

	private onFocus = () => {
		this.focused = true;
		document.addEventListener('selectionchange', this.onSelectionChange);
	};

	private onClick = (ev: MouseEvent) => {
		/**
		 * При тройном клике ЛКМ выделяем не параграфы, а весь текст редактора
		 */
		if (ev.detail === 3) {
			const maxIx = getLength(this.model.tokens);
			this.setSelection(0, maxIx);
			const range = getTextRange(this.element);
			this.saveSelection(range);
		}
	};

	private onBlur = () => {
		this.focused = false;
		document.removeEventListener('selectionchange', this.onSelectionChange);
	};

	private callPostChangeRangeEvent = () => {
		this.postChangeRangeEvent.forEach(event => event());
	};

	private callPostPasteEvents = () => {
		this.postPasteEvents.forEach(event => event());
	};

	private callPostChangeModelEvents = () => {
		this.postChangeModelEvents.forEach(event => event());
	};

	private callPostInputEvent = () => {
		this.postInputEvent.forEach(event => event());
	};

	/**
     * Сохраняет указанный диапазон в текущей записи истории в качестве последнего
     * известного выделения
     */
	private saveSelection(range: TextRange): void {
		const { caret } = this;
		this.caret = range;
		this.history.saveCaret(range);
		if (caret[0] !== range[0] || caret[1] !== range[1]) {
			this.emit('editor-selectionchange');
		}
	}

	/**
     * Обновляет значение модели редактора с добавлением записи в историю изменений
     * @param value Новое значение модели
     * @param action Название действия, которое привело к изменению истории, или
     * `false`, если не надо добавлять действие в историю
     * @param range Диапазон выделения, который нужно сохранить в качестве текущего
     * в записи в истории
     */
	private updateModel(value: Model, action?: string | false, range?: TextRange): Model {
		if (value !== this.model) {
			if (typeof action === 'string') {
				if (range) {
					this.history.push(value, action, range);
				}
			}
			this.model = value;
		}

		if (range) {
			this.saveSelection(range);
		}
		this.callPostChangeModelEvents();

		return this.model;
	}

	/**
     * Обновляет значение модели без вызова callPostChangeModelEvents(). Это нужно для оптимизации в ряде случаев,
	 * например когда меняем цвет и нам не нужно синхронизировать высоту ячейки (это логика добавлена в
	 * callPostChangeModelEvents().
     * @param value Новое значение модели
     * @param action Название действия, которое привело к изменению истории, или
     * `false`, если не надо добавлять действие в историю
     * @param range Диапазон выделения, который нужно сохранить в качестве текущего
     * в записи в истории
     */
	private updateModelWithoutCallPostChangeModelEvents(
		value: Model,
		action?: string | false,
		range?: TextRange,
	): Model {
		if (value !== this.model) {
			if (typeof action === 'string') {
				if (range) {
					this.history.push(value, action, range);
				}
			}
			this.model = value;
		}

		if (range) {
			this.saveSelection(range);
		}

		return this.model;
	}

	/**
     * Синхронизация модели данных редактора с UI.
     * Метод нужно вызывать только в том случае, если есть какие-то изменения
     */
	private syncUI() {
		this.render();

		if (this.focused) {
			const [from, to] = this.caret;
			setRange(this.element, from, to);
		}

		if (this.dirty === DirtyState.DirtyRetainNewline) {
			retainNewlineInViewport(this.getScroller());
		}

		this.dirty = DirtyState.None;
		this.emit('editor-update');
	}

	private scheduleSyncUI() {
		if (!this.rafId) {
			this.rafId = requestAnimationFrame(() => {
				this.rafId = 0;
				if (this.dirty !== DirtyState.None) {
					this.syncUI();
				}
			});
		}
		this.callPostInputEvent();
	}

	private render(): void {
		render(this.element, this.model, {
			fixTrailingLine: true,
			nowrap: this.options.nowrap,
		});
	}

	// запустить кастомное событие на элементе
	private emit(eventName: EventName): void {
		if (this._inited) {
			dispatch<IEditorEventDetails>(this.element, eventName, { editor: this });
		}
		this.callPostInputEvent();
	}

	private normalizeRange([from, to]: TextRange): TextRange {
		const maxIx = getLength(this.model.tokens);
		return [clamp(from, 0, maxIx), clamp(to, 0, maxIx)];
	}

	/**
     * При необходимости удаляет из текста ненужные данные, исходя из текущих настроек
     */
	private sanitizeText<T extends string | Token[]>(text: T): T {
		const { nowrap } = this.options;

		return typeof text === 'string'
			? sanitize(text, nowrap) as T
			: text.map(t => objectMerge(t, { value: sanitize(t.value, nowrap) })) as T;
	}

	private getCompositionRange(): TextRange {
		const range = getTextRange(this.element);
		if (this.compositionStartRange) {
			range[0] = Math.min(this.compositionStartRange[0], range[0]);
		}
		return range;
	}

	private getScroller(): HTMLElement {
		return this.options.scroller || this.element;
	}

	private onDoubleClick = () => {
		// if (!this.isActiveEditable()) {
		// 	this.enableEditable();
		// }
	};
}

function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

/**
 * Вспомогательная функция, которая при необходимости подкручивает вьюпорт
 * к текущему переводу строки
 */
function retainNewlineInViewport(element: Element): void {
	const sel = window.getSelection();
	if (!sel) return;
	const r = sel.getRangeAt(0);

	if (!r?.collapsed) {
		return;
	}

	let rect = r.getClientRects().item(0);
	if ((!rect || !rect.height) && isElement(r.startContainer)) {
		const target = getScrollTarget(r);
		if (target) {
			rect = target.getBoundingClientRect();
		}
	}

	if (rect && rect.height > 0) {
		// Есть прямоугольник, к которому можем прицепиться: проверим, что он видим
		// внутри элемента и если нет, подскроллимся к нему
		const parentRect = element.getBoundingClientRect();
		if (rect.top < parentRect.top || rect.bottom > parentRect.bottom) {
			// Курсор за пределами вьюпорта
			element.scrollTop += rect.top - (parentRect.top + parentRect.height / 2);
		}
	}
}

/**
 * Вернёт элемент, к которому нужно подскроллится.
 */
function getScrollTarget(r: Range): Element | undefined {
	const target = r.startContainer.childNodes[r.startOffset];
	return target && isElement(target) ? target : r.startContainer as Element;
}

/**
 * Проверяет тип события на соответствие значения из DiffActionType и возвращает его значение, либо вернет 'update'.
 * Нужно для записи названия события в истории действий.
 */
function getDiffTypeFromEvent(evt: InputEvent): DiffActionType | string {
	const { inputType } = evt;
	if (inputType) {
		if (startsWith(inputType, 'insert')) {
			return DiffActionType.Insert;
		}

		if (startsWith(inputType, 'delete')) {
			return DiffActionType.Remove;
		}

		if (startsWith(inputType, 'format')) {
			return 'format';
		}
	}

	return 'update';
}

function sanitize(text: string, nowrap?: boolean): string {
	// eslint-disable-next-line no-control-regex
	text = text.replace(/\x00/g, ' ');
	return nowrap
		? text.replace(/(\r\n?|\n)/g, ' ')
		: text.replace(/\r\n?/g, '\n');
}

/**
 * Проверяет тип перетаскиваемого файла (считает количество текстовых и файловых элементов в буффере, если
 * больше текстовых, значит, хотим вставить текст).
 */
// function isFilePaste(data: DataTransfer) {
// 	if (data.types.includes('Files')) {
// 		/* Есть файл в клипборде: это может быть как непосредственно файл, так и скриншот текста из ворда, например.
// 		При этом, даже если это именно файл, рядом может лежать текст, в котором может быть написано имя файла или
// 		путь к нему. То есть мы не можем однозначно ответить, вставляется файл или текст. Поэтому сделаем небольшой
// 		трюк: посчитаем количество текстовых и файловых элементов в буффере, если больше текстовых, значит, хотим
// 		вставить текст */
// 		let files = 0;
// 		let texts = 0;
// 		for (let i = 0; i < data.items.length; i++) {
// 			const item = data.items[i];
// 			if (item.kind === 'string') {
// 				texts++;
// 			} else if (item.kind === 'file') {
// 				files++;
// 			}
// 		}
//
// 		return files >= texts;
// 	}
//
// 	return false;
// }
