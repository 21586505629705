import { utils, WorkBook } from 'xlsx';
import ITableImporter from '../ITableImporter';
import { TableLoadCallback } from '../TableLoadCallback';
import Utils from '../../../utils/impl/Utils';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import {
	FontFamily, FontSize, Model, TextAlign,
} from '../../mext/editor/types';
import { TokenFormat, TokenType } from '../../mext/parser';
import IImportTableStructure from '../IImportTableStructure';
import { notificationError } from '../../../../Notifications/callNotifcation';
import ITableCellTexture from '../../../graphic/table/cells/ITableCellTexture';
import IStyleWorkBook from './IStyleWorkBook';
import { TableGridMap } from '../../../graphic/table/TableGridMap';

type MergesType = {e: {c: number, r: number}, s: {c: number, r: number}}[];
type ColsType = {MDW: number, customwidth: string, wch: number, width: number, wpx: number}[];
/**
 * Сущность, предоставляющая возможность конвертировать структуру xlsx файла в структуры таблиц конструктора.
 */
class XLSXTableImporter implements ITableImporter<null> {
	private readonly EMPTY_CELL_VALUE = '';

	/**
	 * Формирует структуру таблицы через запуск загрузки XLSX файла и возвращает её в callback.
	 * @param loadStructure Callback для отправки структуры таблицы.
	 */
	public getStructure = (loadStructure: TableLoadCallback): void => {
		Utils.File.getXLSXData((workbook: WorkBook) => {
			const isEmptyWorkbook = this.isEmptyWorkbook(workbook);
			if (isEmptyWorkbook) {
				notificationError('Некорректный файл', 'Файл с таблицами пуст или одна из таблиц пустая');
				return;
			}
			const tableStructures: IImportTableStructure[] = [];

			/* SheetNames представляет собой вкладки файла с таблицами (в одном файле может быть не одна таблица).
			Если работаем с несколькими вкладками, то вставится несколько таблиц */
			workbook.SheetNames.forEach(sheetName => {
				const tableStructure = this.convertSheetToStructure(workbook, sheetName);
				tableStructures.push(tableStructure);
			});
			loadStructure(tableStructures);
		});
	};

	/**
	 * Проверяет, пустой ли лист в файле XLSX.
	 * @param workbook Полное описание страницы в файле.
	 */
	private isEmptyWorkbook = (workbook: WorkBook): boolean => {
		if (workbook.SheetNames.length < 1) {
			return true;
		}

		return !workbook.SheetNames.every(sheetName => {
			const sheet = workbook.Sheets[sheetName];
			if (sheet === undefined) {
				throw new ManipulatorError('the sheet not found');
			}

			return true;
		});
	};

	/**
	 * Конвертирует страницу XLSX в структуру таблицы.
	 * @param workbook Структура страницы XLSX.
	 * @param sheetName Имя страницы.
	 */
	private convertSheetToStructure = (workbook: WorkBook, sheetName: string): IImportTableStructure => {
		const tableStructure: IImportTableStructure = {
			cells: [],
			columnCount: 0,
			rowCount: 0,
		};
		const sheet = workbook.Sheets[sheetName];

		// Объект с информацией об объединенных ячейках.
		const sheetMerges = workbook.Sheets[sheetName]['!merges'] as (MergesType | undefined);

		// Все ячейки в формате json.
		const jsonCells: string[][] = utils.sheet_to_json(sheet, {
			header: 1,
			defval: '',
		});
		const columnCount = Math.max(...jsonCells.map(rowCells => rowCells.length));

		// Объект с информацией о ширине колонок в файле. Может отсутствовать.
		const sheetCols = workbook.Sheets[sheetName]['!cols'] as (ColsType | undefined);
		if (sheetCols !== undefined && sheetCols.length < 1) {
			throw new ManipulatorError('invalid sheet cols');
		}
		// if (sheetCols !== undefined && sheetCols.length !== columnCount) {
		// 	throw new ManipulatorError('invalid sheet cols');
		// }

		/* Генерация матрицы с ячейками и пустыми местами. Пустое место может занимать часть объединенной
		 ячейки или полное отсутствие ячейки в этой координате. */
		const cells: (ITableCellTexture | null)[][] = jsonCells
			.map((rowData, rowIndex) => rowData
				.map((cellValue, columnIndex) => {
					cellValue = cellValue.toString().trim();
					if (cellValue === this.EMPTY_CELL_VALUE) {
						return null;
					}

					const cellTexture = this.getDefaultCellTexture(cellValue.toString(), rowIndex, columnIndex);

					// Установка ячейкам информации об их объединении.
					if (sheetMerges !== undefined) {
						this.setCellTextureSpan(sheetMerges, cellTexture, columnIndex, rowIndex);
					}

					return cellTexture;
				}));

		// Построение TableGridMap (см. в описании типа).
		const cellsGrid: TableGridMap = [];
		cells.forEach((rowCells, rowIndex) => {
			rowCells.forEach(((cell, columnIndex) => {
				if (cell === null) {
					return;
				}
				for (let i = rowIndex; i < rowIndex + cell.rowSpan; i++) {
					if (cellsGrid[i] === undefined) {
						cellsGrid[i] = [];
					}
					for (let j = columnIndex; j < columnIndex + cell.columnSpan; j++) {
						cellsGrid[i][j] = cell.id;
					}
				}
			}));
		});

		// Заполнение пустующих (не тех, которые занимают ячейки в объединении, а с полным отсутствии в ней ячейки)
		// координат таблицы.
		this.convertEmptyToEmptyCells(cells, columnCount, cellsGrid);

		tableStructure.cells = this.flatCells(cells);
		tableStructure.rowCount = cellsGrid.length;
		tableStructure.columnCount = columnCount;

		return tableStructure;
	};

	/**
	 * Возвращает текстуру ячейки по умолчанию.
	 * @param value Текст в ячейке.
	 * @param rowIndex Позиция ячейки по оси Y.
	 * @param columnIndex Позиция ячейки по оси X.
	 */
	private getDefaultCellTexture = (value: string, rowIndex: number, columnIndex: number): ITableCellTexture => {
		const model = this.getDefaultModel(value);
		const cellTexture: ITableCellTexture = {
			id: Utils.Generate.UUID4(),
			content: model,
			background: '#ffffff',
			row: rowIndex,
			column: columnIndex,
			rowSpan: 1,
			columnSpan: 1,
		};
		return cellTexture;
	};

	/**
	 * Возвращает из двумерного массива текстур ячеек линейный массив.
	 * @param cells Двумерный массив ячеек, отражающий структуру таблицы.
	 */
	private flatCells = (cells: (ITableCellTexture | null)[][]): ITableCellTexture[] => cells
		.map(rowCells => rowCells
			.filter(cell => cell !== null) as ITableCellTexture[])
		.flat();

	/**
	 * Записывает в ячейки информации об их объединении на основании объекта `merges`.
	 * @param merges Объект с информацией об объединении ячеек.
	 * @param cellTexture Текстура ячейки для обновления.
	 * @param columnIndex Индекс столбца ячейки.
	 * @param rowIndex Индекс строки ячейки.
	 */
	private setCellTextureSpan = (
		merges: MergesType,
		cellTexture: ITableCellTexture,
		columnIndex: number,
		rowIndex: number,
	) => {
		const mergeData = merges.find(m => m.s.c === columnIndex && m.s.r === rowIndex);
		if (mergeData === undefined) {
			return;
		}

		cellTexture.rowSpan = mergeData.e.r - mergeData.s.r + 1;
		cellTexture.columnSpan = mergeData.e.c - mergeData.s.c + 1;
	};

	/**
	 * Заполняет пустующие (не те, которые занимают ячейки в объединении, а с полным отсутствием в ней ячейки)
	 * координаты таблицы.
	 * @param cells Матрица текстур ячеек таблицы с пустующими координатами.
	 * @param columnCount Количество колонок.
	 * @param cellsGrid Карта ячеек (см. описание типа).
	 */
	private convertEmptyToEmptyCells = (
		cells: (ITableCellTexture | null)[][],
		columnCount: number,
		cellsGrid: TableGridMap,
	) => {
		for (let rowIndex = 0; rowIndex < cellsGrid.length; rowIndex++) {
			// Обязательно `columnIndex < columnCount` для заполнения ячейками крайних позиций таблицы.
			for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
				if (cellsGrid[rowIndex] === undefined) {
					cellsGrid[rowIndex] = [];
				}
				if (cellsGrid[rowIndex][columnIndex] === undefined) {
					cells[rowIndex][columnIndex] = this
						.getDefaultCellTexture(this.EMPTY_CELL_VALUE, rowIndex, columnIndex);
				}
			}
		}
	};

	/**
	 * Возвращает модель текста по умолчанию.
	 * @param content Текст модели.
	 */
	private getDefaultModel = (content: string): Model => ({
		id: Utils.Generate.UUID4(),
		lineHeight: 1,
		tokens: [{
			type: TokenType.Text,
			value: content,
			color: '#000000',
			format: 0o00000100,
			fontSize: FontSize.Pt12,
			textAlign: TextAlign.LEFT,
			fontFamily: FontFamily.Default,
			lineHeight: 1,
			sticky: true,
		}],
	});

	/**
	 * Записывает в структуры ячеек информацию о стилях текста.
	 * @deprecated Не во всех случаях библиотека возвращает полную информацию о стилях. Проверить работоспособность
	 * @deprecated с новыми версиями библиотеки.
	 * @param workbook
	 * @param tableStructure
	 */
	private setStyleCells = (
		workbook: IStyleWorkBook,
		tableStructure: IImportTableStructure,
	): IImportTableStructure => {
		if (workbook.Styles === undefined) return tableStructure;

		if (tableStructure.cells.length > workbook.Styles.Fonts.length) return tableStructure;
		for (let i = 0; i < tableStructure.cells.length; i++) {
			// color
			if (workbook.Styles.Fonts[i + 4].color !== undefined) {
				const color = workbook.Styles.Fonts[i + 4].color!.rgb!;
				tableStructure.cells[i].content.tokens[0].color = `#${color}`;
			}
			// bold
			if (workbook.Styles.Fonts[i + 4].bold
				&& !workbook.Styles.Fonts[i + 4].italic
			) {
				tableStructure.cells[i].content.tokens[0].format = TokenFormat.Bold;
			}
			// italic
			if (workbook.Styles.Fonts[i + 4].italic
				&& !workbook.Styles.Fonts[i + 4].bold
			) {
				tableStructure.cells[i].content.tokens[0].format = TokenFormat.Italic;
			}
			// italic and bold
			if (workbook.Styles.Fonts[i + 4].italic
				&& workbook.Styles.Fonts[i + 4].bold
			) {
				// tableStructure.cells[i].content.tokens[0].format = 0o0000011;
				tableStructure.cells[i].content.tokens[0].format = 0b00000011;
				// tableStructure.cells[i].content.tokens[0].format = 1 << 1;
			}
			// fontSize
			if (workbook.Styles.Fonts[i + 4].sz) {
				const fontSize = workbook.Styles.Fonts[i + 4].sz!;
				// Найдем ключ по которому обратимся к enum FontSize
				const convertEnum = Object.entries(FontSize).map(([key, value]) => (
					{ key, number: parseInt(value, 10) }));
				// { key: parseInt(key, 10), value }));
				const key = convertEnum.find((size) => size.number === fontSize)?.key;
				tableStructure.cells[i].content.tokens[0].fontSize = FontSize[key as unknown as keyof typeof FontSize];
			}

			// fontFamily
			if (workbook.Styles.Fonts[i + 4].name) {
				const excelFont = workbook.Styles.Fonts[i + 4].name;
				// Найдем ключ по которому обратимся к enum FontFamily
				const convertEnum = Object.entries(FontFamily).map(([key, value]) => (
					{ key, fontFamily: value.toString() }));
				const key = convertEnum.find((fontFam) => fontFam.fontFamily === excelFont)?.key;
				tableStructure.cells[i].content.tokens[0].fontFamily = FontFamily[
					key as unknown as keyof typeof FontFamily
				];
			}

			// if (i + 4 > tableStructure.cells.length) return tableStructure;
			if (i + 4 > tableStructure.cells.length) break;
		}

		if (workbook.Styles.CellXf === undefined) return tableStructure;
		// i = 1 потому что так приходит с библиотеки
		for (let i = 1; i < workbook.Styles.CellXf.length; i++) {
			// alignment
			if (workbook.Styles.CellXf[i].alignment) {
				const horizontalAlignment = workbook.Styles.CellXf[i].alignment!.horizontal;
				// const verticalAlignment = workbook.Styles.CellXf[i + 1].alignment?.vertical;
				if (horizontalAlignment === 'general') {
					tableStructure.cells[i - 1].content.tokens[0].textAlign = TextAlign.LEFT;
				}
				if (horizontalAlignment === 'center') {
					tableStructure.cells[i - 1].content.tokens[0].textAlign = TextAlign.CENTER;
				}
				if (horizontalAlignment === 'right') {
					tableStructure.cells[i - 1].content.tokens[0].textAlign = TextAlign.RIGHT;
				}
			}
		}
		return tableStructure;
	};
}

export default XLSXTableImporter;
