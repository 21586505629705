import BlindZoneDetector from '../../mechanics/spatial-quadrants/area-mutators/BlindZoneDetector';
import SpatialTableCellArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';
import SpatialAreaType from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialAreaType';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { AnySpatialArea } from '../../Types';
import TableCellContext from './cells/context/TableCellContext';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import Dependent from '../../utils/dependent/Dependent';
import IFrameArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import IDescartesPosition from '../../utils/IDescartesPosition';
import Utils from '../../utils/impl/Utils';

interface ICellSelectionsHandlerDependencies {
	mousePositionObserver: MousePositionObserver,
}

/**
 * Слушатель выделения ячеек внутри компонента таблицы. Выделение происходит прямоугольников,
 * образованным от ячейки начала выделения до позиции мыши.
 */
class CellSelectionHandler extends Dependent<ICellSelectionsHandlerDependencies> {
	private readonly blindZoneDetector: BlindZoneDetector;

	private isMoveRun: boolean;
	private initiatorCellFrame: IFrameArea | null;
	private startFocusCell: TableCellContext | null;
	private initiatorArea: SpatialTableCellArea | null;

	constructor() {
		super();
		this.initiatorArea = null;
		this.startFocusCell = null;
		this.initiatorCellFrame = null;
		this.blindZoneDetector = new BlindZoneDetector();
	}

	public start = (area: SpatialTableCellArea) => {
		this.isMoveRun = true;
		this.initiatorArea = area;
		this.blindZoneDetector.start();
		this.initiatorCellFrame = area.getGlobalFrameArea();
	};

	public move = (offsetX: number, offsetY: number, area: AnySpatialArea | null) => {
		if (!this.isMoveRun
			|| !this.initiatorArea
			|| area === null
			|| area.type !== SpatialAreaType.TABLE_CELL
			|| this.initiatorCellFrame === null) {
			return;
		}

		const areaData = this.initiatorArea.getData();

		// Если находимся на стартовой ячейке - ничего выделять не надо
		if (areaData.areaCellContext === area?.getData().cell) {
			return;
		}

		this.blindZoneDetector.appendOffsets({
			width: 0,
			rotate: 0,
			height: 0,
			x: offsetX,
			y: offsetY,
		});

		// Если курсор находится в слепой зоне (когда мы не учитываем перемещения) - то ничего не делаем
		if (!this.blindZoneDetector.isBlindZoneOverstepping()) {
			return;
		}

		// Помечаем ячейку начальной при фокусировании
		this.startFocusCell = areaData.areaCellContext;

		// Формируем прямоугольник выделения
		const selectionRect: IFrameArea = {
			height: 0,
			width: 0,
			x: 0,
			y: 0,
			rotate: 0,
		};

		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		selectionRect.x = Math.min(mousePosition.x, this.initiatorCellFrame.x);
		selectionRect.y = Math.min(mousePosition.y, this.initiatorCellFrame.y);
		selectionRect.width = Math.abs(mousePosition.x - this.initiatorCellFrame.x);
		selectionRect.height = Math.abs(mousePosition.y - this.initiatorCellFrame.y);

		// Ищем ячейки, которые находятся в этом прямоугольнике
		const cells = areaData.component.getCells();
		const intersectCells = cells.filter(cell => Utils.Geometry
			.intersectArea(cell.getFrameArea(), selectionRect) !== 0);
		const intersectCellContexts = new Set(intersectCells.map(cell => cell.getContext()));

		// Включаем фокус только у найденных ячеек
		const cellContexts = areaData.component.getCellContexts();
		cellContexts.forEach(context => {
			if (intersectCellContexts.has(context)) {
				context.enableFocus();
				return;
			}
			context.disableFocus();
		});
	};

	public stop = () => {
		if (!this.isMoveRun) {
			return;
		}

		this.isMoveRun = false;

		if (this.initiatorArea === null) {
			throw new ManipulatorError('initiator area is null');
		}
		if (!this.blindZoneDetector.isBlindZoneOverstepping()) {
			const areaData = this.initiatorArea.getData();
			const cellContexts = areaData.component.getCellContexts();
			if (this.startFocusCell !== null) {
				this.enableCellFocusOnly(cellContexts, this.startFocusCell);
			} else {
				this.enableCellFocusOnly(cellContexts, areaData.areaCellContext);
			}
		}

		this.startFocusCell = null;
		this.initiatorArea = null;
		this.blindZoneDetector.stop();
	};

	private enableCellFocusOnly = (contexts: TableCellContext[], onlyContext: TableCellContext) => {
		onlyContext.enableFocus();
		contexts.forEach(context => {
			if (context === onlyContext) {
				return;
			}
			context.disableFocus();
		});
	};
}

export default CellSelectionHandler;
