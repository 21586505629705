import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'react-ts-loaders';
import css from './panel.module.scss';
import { MainButton } from '../../../../../ui/main-button/main-button';
import { IGetPrivateTemplateResponse } from '../../../../../../entities/templates/private/api/types';
import {
	DownloadButton, TitleWithEditBtn, GoBackButton,
} from '../../../../../../shared/ui';
import ComponentSketchStructureVisualizer
	from '../../../../../SketchManipulators/SketchStructureVisualizer/ComponentSketchStructureVisualizer';
import { ChangeableTagList } from '../../../../../../widgets/changeable-tag-bar/ui/tag-list/changeable-tag-list';
import { EditTemplateName } from '../../../../../../features/templates/edit-name';
import store from '../../../../../../redux/store/store';
import { templatePrivateAPI } from '../../../../../../entities/templates/private/api/api';
import {
	notifyStructureError,
} from '../../../../../SketchManipulators/SketchPDFGenerator/PDFNotifications';
import savePDFWithNotifications from '../../../../../SketchManipulators/SketchPDFGenerator/savePDFWithNotifications';

interface InfoPanelProps {
	download?: boolean;
	goBack: () => void;
	openTagsModal: () => void;
	unbindTag: (tag: number) => void;
	data: IGetPrivateTemplateResponse;
	editName: (name: string) => Promise<void>;
	isPDFAvailable: boolean;
}

export const InfoPanel = ({
	data,
	goBack,
	editName,
	unbindTag,
	openTagsModal,
	download = false,
	isPDFAvailable,
}: InfoPanelProps) => {
	const { id } = useParams();
	const { template, rootComponent } = data;
	const { tags, name } = template;
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const onInConstructor = () => navigate(`/constructor/${id}`);

	const onDownloadPDF = () => {
		if (id === undefined) {
			throw new Error('id undefined');
		}
		setIsLoading(true);
		store.dispatch(templatePrivateAPI.endpoints?.get.initiate({ id }, { forceRefetch: true }))
			.unwrap()
			.then(res => {
				const { template, rootComponent } = res;
				savePDFWithNotifications(rootComponent, template.name);
			})
			.catch(() => {
				notifyStructureError();
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className={css.wrapper}>
			<GoBackButton styles={css.go_back} onClick={goBack} />
			<div className={css.edit_block}>
				<div>
					<div>
						<EditTemplateName editName={editName} name={name} />
						<div className={css.change_tag_bar}>
							<TitleWithEditBtn title="Теги" onChange={openTagsModal} />
							<ChangeableTagList
								deleteTag={unbindTag}
								activeTags={tags.fromTemplates}
								disabledTags={tags.fromModules}
							/>
						</div>
						<MainButton btnStyle="bright" type="button" onClick={onInConstructor}>
							Редактировать в конструкторе
						</MainButton>
					</div>
				</div>
				<div className={css.controls}>
					{!isPDFAvailable && (
						<span className={css.unavailableText}>Недоступно в пробной версии</span>
					)}
					<DownloadButton download={onDownloadPDF} disabled={!isPDFAvailable || isLoading}>
						{
							isLoading ? (
								<div className={css.buttonContent}>
									Скачать PDF
									<Loader className={css.loader} type="ring" color="#d8d7d7" size={18} />
								</div>
							) : (
								'Скачать PDF'
							)
						}
					</DownloadButton>
				</div>
			</div>
			<div className={css.preview}>
				<ComponentSketchStructureVisualizer structure={{
					id: template.id,
					name: template.name,
					root: rootComponent,
				}}
				/>
			</div>
		</div>
	);
};
