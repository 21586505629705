import ISketchStructure from '../../Sketch/ISketchStructure';
import MultiPageManipulator from '../MultiPageManipulator';
import StaticGraphicFactory from '../../Sketch/factories/graphic/StaticGraphicFactory';
import IPagesComponentTree from '../../Sketch/component-tree/IPagesComponentTree';
import PagesComponentTree from '../../Sketch/component-tree/impl/PagesComponentTree';
import StaticComponentFactory from '../../Sketch/factories/component/StaticComponentFactory';
import TableComponent from '../../Sketch/components/table/TableComponent';
import SketchComponentType from '../../Sketch/components/SketchComponentType';

/**
 * Манипулятор компонентов, предназначенный только для статической визуализации структуры компонентов.
 */
class SketchStructureVisualizer extends MultiPageManipulator<
	IPagesComponentTree,
	StaticGraphicFactory,
	StaticComponentFactory,
	null,
	null> {
	constructor(container: HTMLDivElement, structure: ISketchStructure) {
		super(container);

		this.componentTree = new PagesComponentTree(container);
		this.componentFactory = new StaticComponentFactory();
		this.graphicFactory = new StaticGraphicFactory();

		this.componentTree.connectDependencies({
			graphicFactory: this.graphicFactory,
			componentFactory: this.componentFactory,
		});
		this.graphicFactory.connectDependencies({
			componentTree: this.componentTree,
		});
		this.componentFactory.connectDependencies(null);

		this.componentTree.injectDependencies();
		this.graphicFactory.injectDependencies();
		this.componentFactory.injectDependencies();

		this.setID(structure.id);
		this.setName(structure.name);
		this.componentTree.load(structure.root);

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);
	}
}

export default SketchStructureVisualizer;
