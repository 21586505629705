import _ from 'lodash';
import ITableCellTexture from '../ITableCellTexture';
import TableCell from '../TableCell';
import ITableCellBuildProperties from './ITableCellBuildProperties';
import Editor from '../../../../mechanics/mext/editor';
import { TokenFormat } from '../../../../mechanics/mext/parser';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import ControlTableCell from '../ControlTableCell';
import ExtendTableCell from '../ExtendTableCell';

class TableCellContext {
	// Функциональная ячейка, в которую будет происходить ввод текста.
	private readonly controlCell: TableCell;
	// Ячейки расширения, не несут никакой логической нагрузки, только для визуализации.
	private readonly extendCells: TableCell[];

	private readonly texture: ITableCellTexture;
	private readonly editor: Editor;

	private isFocus: boolean;
	private currentTableStartRows: number[];

	constructor(texture: ITableCellTexture) {
		this.texture = _.cloneDeep(texture);
		this.controlCell = this.buildControlTableCell(texture);
		this.editor = new Editor(this.controlCell.getElement(), {
			parse: {},
			nowrap: false,
			value: '',
			resetFormatOnNewline: false,
			shortcuts: {
				'Cmd+Z': editor => editor.undo(),
				'Cmd+Y': editor => editor.redo(),
				Enter: () => this.editor.disableEditable(),
				'Cmd+Shift+Z': editor => editor.redo(),
				'Cmd+B': editor => editor.toggleFormat(TokenFormat.Bold),
				'Cmd+I': editor => editor.toggleFormat(TokenFormat.Italic),
				'Cmd+U': editor => editor.toggleFormat(TokenFormat.Strike),
			},
		});
		this.isFocus = false;
		this.extendCells = [];
		this.currentTableStartRows = [];
		this.editor.model = texture.content;
	}

	/**
	 * Ставит курсор текста внутрь ячейки.
	 */
	public enableTextFocus = () => {
		this.editor.focus();
	};

	public enableMutationMode = () => {
		this.editor.enableEditable();
	};

	public disableMutationMode = () => {
		this.editor.disableEditable();
	};

	public setTableStartRows = (rows: number[]) => {
		const startRows = rows.filter(value => value !== 0);
		if (this.currentTableStartRows.toString() === startRows.toString()) {
			return;
		}
		this.currentTableStartRows = startRows;
		this.rebuildCells();
	};

	public addPostChangeModelListener = (listener: VoidFunction) => {
		this.editor.addPostChangeModelEvent(listener);
	};

	private rebuildCells = () => {
		// Определить, в каких местах ячейки будет происходить разрыв относительно начальной строки.
		const splitRows: number[] = [];
		for (let i = 0; i < this.currentTableStartRows.length; i++) {
			if (this.currentTableStartRows[i] > this.texture.row
				&& this.currentTableStartRows[i] < this.texture.row + this.texture.rowSpan) {
				splitRows.push(this.currentTableStartRows[i] - this.texture.row);
			}
		}

		// Рассчитать необходимые ячейки для физического представления контекста.
		const cellProperties: ITableCellBuildProperties[] = [];

		let prevRow = this.texture.row;
		for (let i = 0; i < splitRows.length; i++) {
			const row = prevRow;
			const rowSpan = this.texture.row + splitRows[i] - prevRow;
			const properties: ITableCellBuildProperties = {
				row,
				rowSpan,
				column: this.texture.column,
				columnSpan: this.texture.columnSpan,
			};

			cellProperties.push(properties);
			prevRow += rowSpan;
		}
		cellProperties.push({
			row: prevRow,
			rowSpan: this.texture.row + this.texture.rowSpan - prevRow,
			column: this.texture.column,
			columnSpan: this.texture.columnSpan,
		});

		this.applyCellProperties(cellProperties);
	};

	public getTexture = (): ITableCellTexture => ({
		...this.texture,
		content: this.editor.getCopyModel(),
	});

	public enableFocus = () => {
		if (this.isFocus) {
			return;
		}

		this.controlCell.enableFocus();
		this.extendCells.forEach(cell => cell.enableFocus());
		this.isFocus = true;
	};

	public disableFocus = () => {
		if (!this.isFocus) {
			return;
		}

		this.controlCell.disableFocus();
		this.extendCells.forEach(cell => cell.disableFocus());
		this.isFocus = false;
	};

	public setBackground = (color: string) => {
		this.texture.background = color;
		this.controlCell.setBackground(color);
		this.extendCells.forEach(cell => cell.setBackground(color));
	};

	public getID = () => this.texture.id;

	public hasFocus = (): boolean => this.isFocus;

	public getEditor = (): Editor => this.editor;

	public getTableCells = (): TableCell[] => [this.controlCell, ...this.extendCells];

	private buildControlTableCell = (properties: ITableCellBuildProperties): TableCell => {
		const cell = new ControlTableCell(this);
		this.setupBaseTableCell(cell, properties);
		return cell;
	};

	private buildExtendTableCell = (properties: ITableCellBuildProperties): TableCell => {
		const cell = new ExtendTableCell(this);
		this.setupBaseTableCell(cell, properties);
		return cell;
	};

	private setupBaseTableCell = (cell: TableCell, properties: ITableCellBuildProperties) => {
		const {
			row, rowSpan, column, columnSpan,
		} = properties;

		cell
			.setBackground(this.texture.background)
			.setRow(row)
			.setRowSpan(rowSpan)
			.setColumn(column)
			.setColumnSpan(columnSpan);
	};

	private applyCellProperties = (cellProperties: ITableCellBuildProperties[]) => {
		if (cellProperties[0] === null) {
			throw new ManipulatorError('functional cell property not found');
		}

		this.applyCellPropertiesToCell(this.controlCell, cellProperties[0]);

		// Удалить лишние ячейки расширения.
		this.extendCells.length = cellProperties.length - 1;

		for (let i = 1; i < cellProperties.length; i++) {
			if (this.extendCells[i - 1] === undefined) {
				this.extendCells[i - 1] = this.buildExtendTableCell(cellProperties[i]);
			} else {
				this.applyCellPropertiesToCell(this.extendCells[i - 1], cellProperties[i]);
			}
		}
	};

	private applyCellPropertiesToCell = (cell: TableCell, cellProperties: ITableCellBuildProperties) => {
		cell.setRow(cellProperties.row);
		cell.setColumn(cellProperties.column);
		cell.setRowSpan(cellProperties.rowSpan);
		cell.setColumnSpan(cellProperties.columnSpan);
	};
}

export default TableCellContext;
