import TextPanelView from './TextPanelView';
import ITextModelSelection from './ITextModelSelection';
import {
	FontFamily, FontSize, Model, TextAlign, TextRange,
} from '../../../../mechanics/mext/editor/types';
import { Token, TokenFormat } from '../../../../mechanics/mext/parser';
import Utils from '../../../../utils/impl/Utils';
import IComponentContainingText from '../../../../components/IComponentContainingText';
import Editor from '../../../../mechanics/mext/editor';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import { tokenRange } from '../../../../mechanics/mext/formatted-string/utils';
import ITextOption from './line-height/ITextOption';
import LineHeight from './line-height/LineHeight';
import SketchComponentType from '../../../../components/SketchComponentType';
import TextComponent from '../../../../components/text/TextComponent';
import TableComponent from '../../../../components/table/TableComponent';
import { ITokenBase } from '../../../../mechanics/mext/parser/types';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';

class TextPanel extends TextPanelView {
	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.fontSizeSelector.addOptionSelectListener(this.onFontSizeChange);
		this.lineHeightSelector.addOptionSelectListener(this.onLineHeightChange);
		this.fontFamilySelector.addOptionSelectListener(this.onFontFamilyChange);

		this.addInputColorEvent(this.onColorInput);
		this.addTextAlignChangeEvent(this.onTextAlignChange);
		this.addBoldClickEvent(this.onBoldChange);
		this.addItalicClickEvent(this.onItalicChange);
	}

	/** Для синхронизации панели текста есть 3 варианта:
	 * 1 - Когда в фокусе несколько текстовых редакторов (несколько текстовых компонентов либо несколько ячеек таблиц).
	 * 2 - Когда в фокусе 1 редактор без режима редактирования.
	 * 3 - Когда в фокусе 1 редактор в режиме редактирования.
	 *
	 * В первом варианте нужно проверить все свойства одной модели на полное совпадение. Например, все токены модели
	 * имеют одинаковое семейство шрифта и поэтому мы сохраняем семейство в объект, содержащий одинаковые свойства
	 * одной модели. Дальше нужно проделать то же самое с остальными редакторами в фокусе и после этого сравнить объекты
	 * на соответствие. Если есть одинаковые свойства, то их нужно отобразить на панели текста, если нет - оставить поле
	 * свойства пустым, либо кнопку не активной (например в случае с форматированием в "жирный").
	 *
	 * Второй вариант аналогично первому, просто у нас одна модель для выделения похожих свойств.
	 *
	 * Третий вариант предполагает работу с диапазоном выделения текста. При этом мигающая каретка перед каким-то
	 * символом также является диапазоном, просто поля from, to в этом случае будут равны. Логика такая же - мы ищем
	 * похожие свойства в случае если поля from, to не равны друг другу (это когда пользователь выделил какую-то часть
	 * текста, то есть диапазон не нулевой). Если поля from, to диапазона выделения равны, мы находим токен, в котором
	 * находится каретка и отображаем свойства из него.
	 * @param components
	 */
	public sync = (components: IComponentContainingText[]) => {
		this.resetValuesAll();

		// Получить все модели в фокусе
		const focusModelsSelection = this.getFocusTextModels(components);

		// Если токенов нет, то отображаем общие стили компонентов в фокусе
		if (focusModelsSelection === null) {
			return;
		}

		/* 3 вариант */
		if (focusModelsSelection.IsSingleEditorInFocus
			&& focusModelsSelection.editor
			&& focusModelsSelection.editor.isActiveEditable()) {
			if (focusModelsSelection.editor === null) throw new ManipulatorError('Editor is null');

			this.syncStateFromEditableToken(focusModelsSelection.editor);
			return;
		}

		/* 1, 2 вариант */
		this.syncStateFromSameProperties(focusModelsSelection.models);
	};

	private onColorInput = (color: string) => {
		this.useCases.setFocusTextColor(color);
	};

	private onTextAlignChange = (align: TextAlign) => {
		this.useCases.setFocusTextAlign(align);
	};

	private onFontFamilyChange = (option: ITextOption<FontFamily>) => {
		this.useCases.setFocusTextFontFamily(option.value);
		this.fontFamilySelector.setSelectedOption(option.value);
	};

	private onLineHeightChange = (option: ITextOption<LineHeight>) => {
		this.useCases.setFocusTextLineHeight(option.value);
		this.lineHeightSelector.setSelectedOption(option.value);
	};

	private onFontSizeChange = (option: ITextOption<FontSize>) => {
		this.useCases.setFocusTextFontSize(option.value);
		this.fontSizeSelector.setSelectedOption(option.value);
	};

	private onBoldChange = () => {
		this.useCases.changeFocusTextBold();
	};

	private onItalicChange = () => {
		this.useCases.changeFocusTextItalic();
	};

	/**
	 * Возвращает все текстовые модели и токены (только те, которые в фокусе) у компонентов в фокусе.
	 * В случае с таблицей у нас 2 варианта: когда выделены какие-то ячейки и когда в фокусе компонент(ы) таблиц и при
	 * этом и ячейки соответственно находятся не в фокусе и вернуться должны все модели (ячейки таблиц).
	*/
	private getFocusTextModels = (components: IComponentContainingText[]): ITextModelSelection | null => {
		const result: ITextModelSelection = {
			IsSingleEditorInFocus: false,
			editor: null,
			models: [],
			tokens: [],
		};

		/* Проверим случай когда только 1 текстовый компонент в фокусе и его редактор в режиме редактирования */
		if (components.length === 1 && components[0].type === SketchComponentType.TEXT) {
			const textComponent = components[0] as TextComponent;
			textComponent.getEditors().forEach((e) => {
				if (e.isActiveEditable()) result.IsSingleEditorInFocus = true;

				/* В случае с одиночным текстовым компонентом в фокусе и в режиме редактирования - нам достаточно
				записать сам редактор, потом из него достанем диапазон и модель */
				result.editor = e;
				result.models.push(e.model);
			});
			return result;
		}

		/* В фокусе 1 таблица. Здесь может быть вариант без выделенных ячеек (вся таблица) и с выделенными ячейками */
		if (components.length === 1 && components[0].type === SketchComponentType.TABLE) {
			const tableComponent = components[0] as TableComponent;
			const cells = tableComponent.getFocusCellContexts();

			/* 1 ячейка в режиме редактирования - дальше будет синхронизация по диапазону выделения, поэтому
			поставим соответствующий флаг */
			if (cells?.length === 1 && cells[0].getEditor().isActiveEditable()) {
				result.editor = cells[0].getEditor();
				result.IsSingleEditorInFocus = true;
				result.models.push(cells[0].getEditor().model);
				return result;
			}

			// 	в фокусе таблица без выделенных ячеек - значит берем все ячейки
			if (cells === null) {
				const models = tableComponent.getEditors();
				models.forEach(editor => result.models.push(editor.model));
				return result;
			}

			cells.forEach(cell => result.models.push(cell.getEditor().model));

			return result;
		}

		components.forEach(component => {
			if (component.type === SketchComponentType.TEXT) {
				const textComponent = component as TextComponent;
				const textEditors = textComponent.getEditors();

				textEditors.forEach(editor => {
					const focusTokens = editor.getSelectedTokens().map(range => range.token);
					if (focusTokens === null) {
						return;
					}
					result.models.push(editor.model);
					result.tokens.push(...focusTokens);
				});
			}
			/* На данный момент нельзя выделить ячейки какие-то таблицы и одновременно взять в фокус другой компонент -
			* поэтому считаем, что если компонент в фокусе не 1 значит получаем все ячейки для синхронизации */
			if (component.type === SketchComponentType.TABLE) {
				const tableComponent = component as TableComponent;
				const models = tableComponent.getEditors();
				models.forEach((editor) => {
					const focusTokens = editor.getSelectedTokens().map(range => range.token);
					result.tokens.push(...focusTokens);
					result.models.push(editor.model);
				});
			}
		});

		return result.tokens.length === 0 ? null : result;
	};

	/**
	 * Устанавливает значения в панель текста ориентируясь на свойства модели и диапазона выделения текста в
	 * режиме редактирования.
	 */
	private syncStateFromEditableToken = (editor: Editor) => {
		const range: TextRange = editor.getSelection();
		const [from, to] = range;
		// Находим индексы токенов в диапазоне выделения
		const [start, end] = tokenRange(editor.model.tokens, from, to);
		if (start.index === -1 || end.index === -1 || end.index < start.index) {
			// Невалидные данные, ничего не делаем
			return;
		}

		// Если диапазон не нулевой (пользователь выделил несколько символов)
		if (from !== to) {
			/* Проверим сколько токенов в выделенном диапазоне. Если только 1 токен, то синхронизируем панель
			по его свойствам. */
			if (start.index === end.index) {
				this.setPropertiesFromSingleToken(editor.model.tokens[start.index]);
				return;
			}

			const tokens: Token[] = [];
			for (let i = 0; i < editor.model.tokens.length; i++) {
				if (i >= start.index && i <= end.index) {
					tokens.push(editor.model.tokens[i]);
				}
			}
			const sameTokens = Utils.Object.checkFieldsEquality<ITokenBase>(...tokens);
			if (sameTokens.format !== null) {
				if (sameTokens.format === TokenFormat.Bold) {
					this.enableBold();
				}
				if (sameTokens.format === TokenFormat.Italic) {
					this.enableItalic();
				}
			}
			if (sameTokens.fontSize !== null) {
				this.fontSizeSelector.setSelectedOption(sameTokens.fontSize as FontSize);
			}
			return;
		}

		// Если мигает каретка (нет диапазона выделения текста)
		this.setPropertiesFromSingleToken(editor.model.tokens[start.index]);
	};

	/**
	 * Синхронизирует панель текста (кроме высоты строки так как она в модели) из переданного токена. Используется как
	 * вспомогательный метод при синхронизации панели текста.
	 * @param token - токен, с которого будут устанавливаться значения панели текста.
	 */
	private setPropertiesFromSingleToken = (token: ITokenBase): void => {
		this.fontFamilySelector.setSelectedOption(token.fontFamily as FontFamily);
		this.fontSizeSelector.setSelectedOption(token.fontSize as FontSize);
		this.setColor(token.color as string);

		this.enableAlign(token.textAlign as TextAlign);
		this.lineHeightSelector.setSelectedOption(token.lineHeight);
		if (token.format & TokenFormat.Bold) {
			this.enableBold();
		}
		if (token.format & TokenFormat.Italic) {
			this.enableItalic();
		}
	};

	/**
	 * Устанавливает значения в поля основываясь на результате вычисления пересечения свойств.
	 */
	private syncStateFromSameProperties = (models: Model[]) => {
		const tokens = models.map(model => model.tokens).flat();
		if (!tokens.length) return;

		const sameTokens = Utils.Object.checkFieldsEquality<ITokenBase>(...tokens);

		if (sameTokens.textAlign !== null) {
			this.enableAlign(sameTokens.textAlign as TextAlign);
		}
		if (sameTokens.format !== null) {
			if (sameTokens.format === TokenFormat.Bold) {
				this.enableBold();
			}
			if (sameTokens.format === TokenFormat.Italic) {
				this.enableItalic();
			}
		}
		if (sameTokens.fontSize !== null) {
			this.setFontSize(sameTokens.fontSize as FontSize);
		}
		if (sameTokens.color !== null) {
			this.setColor(sameTokens.color as string);
		}
		if (sameTokens.fontFamily !== null) {
			this.setFontFamily(sameTokens.fontFamily as FontFamily);
		}
		if (sameTokens.lineHeight !== null) {
			this.setLineHeight(sameTokens.lineHeight as LineHeight);
		}
	};
}

export default TextPanel;
